import React from "react";
import SendSvg from "../../assets/icon/svgJsx/SendSvg";

export const MessageInput = ({ value, setValue, handleSubmit }) => {
  return (
    <div className="messageInput">
      <input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Write your comment"
        className="mr-2"
      />
      <button onClick={() => handleSubmit()}>
        <SendSvg />
      </button>
    </div>
  );
};
