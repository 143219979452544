import api from "./api";

export async function callRestPost(url, data, headerParams) {
  const options = {
    headers: { ...headerParams }, // Merge headerParams with options.headers
    method: "POST",
    data: data,
  };

  const result = await api.post(url, data, options);
  return result;
}

export async function callRestGet(url, headerParams) {
  const options = {
    method: "GET",
    ContentType: "application/json",
    ...headerParams,
  };
  const result = await api.get(url, options);

  return result;
}

export async function callRestPut(url, data) {
  const options = {
    method: "PUT",
    data: data,
  };
  const result = await api.put(url, data, options);
  return result;
}

export async function callRestDelete(url) {
  const options = {
    method: "DELETE",
  };
  const result = await api.delete(url, options);
  return result;
}
