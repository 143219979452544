import React, { useRef, useState } from "react";
import DataFailureAlert from "~/components/child/tradingViewMainChart/ui/DataFailureAlert";
import { chartSize } from "~/components/child/tradingViewMainChart/utils/helpers/tools/createChartStyles";
import { TVChartContainer } from "~/components/child/tradingViewMainChart/tvChartContainer/imbalances/TVChartContainer";
import Header from "./Header";
import { Loading } from "~/components/child/ui/Loading";
import { useMainChartDetails } from "~/utils/hooks";

const Imbalances = () => {
  const [pair, setPair] = useState("BTCUSDT");
  const [isPlotZones, setIsPlotZones] = useState(false);
  const [imbalanceValue, setImbalanceValue] = useState(99);
  const { theme, isLoading } = useMainChartDetails();

  const chartRef = useRef();

  const handleClear = async () => {
    try {
      if (chartRef.current) {
        await chartRef.current.clearZonesMethod();
        setIsPlotZones(false);
      }
    } catch (error) {
      console.log("handleClear() imbalances Error => ", error);
      setIsPlotZones(true);
    }
  };

  const handlePlotZones = async () => {
    try {
      setIsPlotZones(true);
      if (chartRef.current) {
        chartRef.current.plotZonesMethod();
      }
    } catch (error) {
      setIsPlotZones(false);
      console.log("failed handlePlotZones() Error => ", error);
    }
  };

  return (
    <div className={`${chartSize?.fullScreen}`}>
      {!isLoading && theme ? (
        <>
          <Header
            pair={pair}
            setPair={setPair}
            handleClear={handleClear}
            handlePlotZones={handlePlotZones}
            isPlotZones={isPlotZones}
            setIsPlotZones={setIsPlotZones}
            imbalanceValue={imbalanceValue}
            setImbalanceValue={setImbalanceValue}
            theme={theme}
          />

          <div className="h-full">
            <TVChartContainer
              ref={chartRef}
              pair={pair}
              setIsPlotZones={setIsPlotZones}
              imbalanceValue={imbalanceValue}
              theme={theme}
            />
          </div>
        </>
      ) : !isLoading ? (
        <DataFailureAlert />
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Imbalances;
