import React from "react";

import Description from "~/components/child/ui/Description";
import AnalysisHeader from "~/components/child/AnalysisHeader";
import { ReceiveNotification } from "~/components/child/notification/ReceiveNotification";
import Point from "./Point";

import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";
import { riskToRewardResult } from "~/utils/riskToRewardResult";
import { calculateTotalAverage, calculateRangeAverage } from "./utils";

const Details = ({
  analysisValues,
  setAnalysisValues,
  isEditMode,
  setDataHasChanged,
  setNotification,
  notification,
}) => {
  const calculateRiskToReward = (ep, sl, tpList) => {
    tpList.map((item, index) => {
      if (item.value.length > 0)
        setAnalysisValues((prevData) => {
          const newData = { ...prevData };
          newData.technical.points.tp[index].rr = riskToRewardResult(
            calculateTotalAverage(ep),
            calculateRangeAverage(sl),
            calculateRangeAverage(item.value)
          );
          return newData;
        });
    });
  };

  const handlePointChange = (updatedPointVal, pointType, index) => {
    setAnalysisValues((prevData) => {
      const newData = { ...prevData };
      newData.technical.points[pointType][index] = updatedPointVal;

      const epAvg = calculateTotalAverage(
        newData.technical.points.ep.filter((item) => item.value !== "")
      );
      const slValue = newData.technical.points.sl[0].value;
      const tpList = newData.technical.points.tp;
      newData.technical.points.tp = tpList.map((item) => {
        if (item.value.length > 0) {
          item.rr = riskToRewardResult(
            epAvg,
            calculateRangeAverage(slValue),
            calculateRangeAverage(item.value)
          );
        } else item.rr = null;
        return item;
      });

      return newData;
    });
    setDataHasChanged(true);
  };

  const handleDeletePoint = (pointType, index) => {
    setAnalysisValues((prevState) => {
      const updatedPoints = { ...prevState.technical.points };
      updatedPoints[pointType].splice(index, 1); // Remove the item at the specified index
      calculateRiskToReward(
        updatedPoints.ep,
        updatedPoints.sl[0].value,
        updatedPoints.tp
      );
      return {
        ...prevState,
        points: updatedPoints,
      };
    });
    setDataHasChanged(true);
  };

  return (
    <>
      <div className="bg-storm-10 rounded-lg text-sky-70 flex flex-col gap-4 justify-between p-2">
        {!isEditMode && (
          <span>
            New {changeWordFormatToTitleCase(analysisValues.technical.type)}{" "}
            Technical
          </span>
        )}
        <AnalysisHeader
          pairs={analysisValues.new.pair}
          timeFrame={analysisValues.technical.timeFrame.value}
          positionType={analysisValues.technical.positionType}
          type="Technical"
        />
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            {analysisValues?.technical?.points?.ep?.map((epPoint, index) => (
              <Point
                key={`ep-${index}`}
                title={`EP${
                  analysisValues?.technical?.points?.ep?.length > 1
                    ? index + 1
                    : ""
                }${
                  index === 0 && analysisValues?.technical?.type === "setup"
                    ? " *"
                    : ""
                }`}
                titleColor="text-sky-key"
                pointVal={epPoint}
                setPointVal={(updatedPointVal) => {
                  handlePointChange(updatedPointVal, "ep", index);
                }}
                onDelete={
                  index !== 0
                    ? () => {
                        handleDeletePoint("ep", index);
                      }
                    : undefined
                }
              />
            ))}
            <button
              className="text-sky-40 font-roboto text-sm text-left"
              onClick={() => {
                setAnalysisValues((prevState) => {
                  const updatedPoints = { ...prevState.technical.points };
                  updatedPoints.ep = [
                    ...updatedPoints.ep,
                    { value: "", desc: "" },
                  ];

                  return {
                    ...prevState,
                    technical: {
                      ...prevState.technical,
                      points: updatedPoints,
                    },
                  };
                });
              }}
            >
              + Add another EP
            </button>
          </div>
          <div className="flex flex-col gap-2">
            {analysisValues?.technical?.points?.tp?.map((tpPoint, index) => (
              <Point
                key={`tp-${index}`}
                title={`TP${
                  analysisValues?.technical?.points?.tp?.length > 1
                    ? index + 1
                    : ""
                }${
                  index === 0 && analysisValues?.technical?.type === "setup"
                    ? " *"
                    : ""
                }`}
                titleColor="text-leaf-key"
                pointVal={tpPoint}
                setPointVal={(updatedPointVal) => {
                  handlePointChange(updatedPointVal, "tp", index);
                }}
                onDelete={
                  index !== 0
                    ? () => {
                        handleDeletePoint("tp", index);
                      }
                    : undefined
                }
              />
            ))}
            <button
              className="text-sky-40 font-roboto text-sm text-left"
              onClick={() => {
                setAnalysisValues((prevState) => {
                  const updatedPoints = { ...prevState.technical.points };
                  updatedPoints.tp = [
                    ...updatedPoints.tp,
                    { value: "", desc: "" },
                  ];
                  return {
                    ...prevState,
                    technical: {
                      ...prevState.technical,
                      points: updatedPoints,
                    },
                  };
                });
              }}
            >
              + Add another TP
            </button>
          </div>
          {analysisValues?.technical?.points?.sl && (
            <Point
              title={`SL${
                analysisValues?.technical?.type === "setup" ? " *" : ""
              }`}
              titleColor="text-infrared-key"
              pointVal={analysisValues?.technical?.points.sl[0]}
              setPointVal={(updatedPointVal) => {
                handlePointChange(updatedPointVal, "sl", 0);
              }}
            />
          )}
        </div>
      </div>
      {!isEditMode && (
        <ReceiveNotification
          className="mt-4 bg-storm-10 border-none w-auto"
          users={[...analysisValues?.technical?.notificationUsers]}
          onClickHandler={() =>
            setNotification({ ...notification, modal: !notification.modal })
          }
          switchIsOn={notification.switch}
          setSwitchIsOn={() =>
            setNotification({ ...notification, switch: !notification.switch })
          }
        />
      )}
      <Description
        description={analysisValues?.technical?.description}
        setDescription={(updatedDesc) => {
          setAnalysisValues({
            ...analysisValues,
            technical: {
              ...analysisValues.technical,
              description: updatedDesc,
            },
          });
          setDataHasChanged(true);
        }}
        limitNumber={7000}
      />
    </>
  );
};

export default Details;
