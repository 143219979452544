import Symbols from "~/components/child/Symbols";
import Dropdown from "~/components/child/ui/DropDown";
import { useTimeFramesList } from "~/utils/hooks";
import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";

const NewAnalysis = ({
  isEditMode,
  analysisValues,
  setAnalysisValues,
  setTechChartData,
  setFundChartData,
}) => {
  const timeFramesList = useTimeFramesList()?.data;
  return (
    <div className="px-2">
      <h2 className=" text-sky-70 text-base  font-roboto">New analysis</h2>

      <span className="text-oil-60 text-base font-roboto ">
        Please select the analysis information
      </span>

      <div className="flex flex-col gap-4 mt-8">
        <Symbols
          value={analysisValues?.new?.pair}
          onSelect={(item) => {
            if (analysisValues?.new?.pair?.value !== item?.value) {
              setTechChartData(null);
              setFundChartData(null);
            }
            setAnalysisValues({
              ...analysisValues,
              new: {
                ...analysisValues?.new,
                pair: {
                  key: item.key,
                  value: item.value,
                  image: item.image,
                  baseSymbol: item.baseSymbol,
                  quoteSymbol: item.quoteSymbol,
                  exchange: item.exchange,
                },
              },
            });
          }}
        />
        <Dropdown
          title="Analysis Type"
          value={
            analysisValues?.new?.analysisType
              ? {
                  key: analysisValues?.new?.analysisType,
                  value: changeWordFormatToTitleCase(
                    analysisValues?.new?.analysisType
                  ),
                }
              : undefined
          }
          optionsList={["Technical", "Fundamental", "on-chain"].map((type) => ({
            key: type.toLowerCase(),
            value: type,
          }))}
          renderItem={(item) => (
            <span className="py-3 pl-2 text-xs font-roboto w-full flex cursor-pointer">
              {item.value}
            </span>
          )}
          onSelect={(item) => {
            if (
              analysisValues?.new?.analysisType !== item?.value.toLowerCase()
            ) {
              setTechChartData(null);
              setFundChartData(null);
            }
            setAnalysisValues({
              ...analysisValues,
              new: {
                ...analysisValues.new,
                analysisType: item.key,
              },
            });
          }}
          editable={!isEditMode}
        />

        {analysisValues?.new?.analysisType === "technical" && (
          <>
            <Dropdown
              title="Technical Type"
              value={
                analysisValues?.technical?.type
                  ? {
                      key: analysisValues?.technical?.type,
                      value: changeWordFormatToTitleCase(
                        analysisValues?.technical?.type
                      ),
                    }
                  : undefined
              }
              optionsList={["Setup", "Analysis"].map((type) => ({
                key: type.toLowerCase(),
                value: type,
              }))}
              renderItem={(item) => (
                <span className="py-3 pl-2 text-xs font-roboto w-full flex cursor-pointer">
                  {item.value}
                </span>
              )}
              onSelect={(item) => {
                if (
                  analysisValues?.technical?.type !== item?.value.toLowerCase()
                ) {
                  setTechChartData(null);
                  setFundChartData(null);
                }
                setAnalysisValues({
                  ...analysisValues,
                  technical: {
                    ...analysisValues.technical,
                    type: item.key,
                  },
                });
              }}
            />
            <Dropdown
              title="Time frame"
              value={analysisValues?.technical?.timeFrame || undefined}
              optionsList={timeFramesList?.map((timeFrame) => ({
                key: timeFrame.id,
                value: timeFrame.time_frame,
              }))}
              renderItem={(item) => (
                <span className="py-3 pl-2 text-xs font-roboto w-full flex cursor-pointer">
                  {item.value}
                </span>
              )}
              onSelect={(item) => {
                if (
                  analysisValues?.technical?.timeFrame?.value !== item?.value
                ) {
                  setTechChartData(null);
                  setFundChartData(null);
                }
                setAnalysisValues({
                  ...analysisValues,
                  technical: {
                    ...analysisValues.technical,
                    timeFrame: {
                      key: item.key,
                      value: item.value,
                    },
                  },
                });
              }}
            />

            <Dropdown
              title="Position Type"
              value={
                analysisValues?.technical?.positionType
                  ? {
                      key: analysisValues?.technical?.positionType,
                      value: changeWordFormatToTitleCase(
                        analysisValues?.technical?.positionType
                      ),
                    }
                  : undefined
              }
              optionsList={["Long", "Short"]?.map((positionType) => ({
                key: positionType.toLocaleLowerCase(),
                value: positionType,
              }))}
              renderItem={(item) => (
                <span className="py-3 pl-2 text-xs font-roboto w-full flex cursor-pointer">
                  {item.value}
                </span>
              )}
              onSelect={(item) => {
                if (
                  analysisValues?.technical?.positionType !==
                  item?.value.toLowerCase()
                ) {
                  setTechChartData(null);
                  setFundChartData(null);
                }
                setAnalysisValues({
                  ...analysisValues,

                  technical: {
                    ...analysisValues.technical,
                    positionType: item.key,
                  },
                });
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default NewAnalysis;
