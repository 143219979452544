import React, { useState, useEffect } from "react";
import Pagination from "rc-pagination/lib/Pagination";
import { NavLink } from "react-router-dom";

// import ArrowLeftSvg from "~/assets/icon/svgJsx/ArrowLeftSvg";
import ArrowRightSvg from "~/assets/icon/svgJsx/ArrowRightSvg";
import ArrowLineRightSvg from "~/assets/icon/svgJsx/ArrowLineRightSvg";
import filterSvg from "~/assets/icon/filter.svg";

import { Filter } from "~/components/child/Filter";
import { transformedFilterItems } from "~/utils/getTransformedData";
import { Loading } from "~/components/child/ui/Loading";

import { useUsersList } from "~/utils/hooks";

const UserList = () => {
  const [appliedFilters, setAppliedFilters] = useState({
    pairs: [],
    date: [],
    analyzers: [],
    type: [],
    timeFrame: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [filterItemsCount, setFilterItemsCount] = useState(0);

  const analyzersList = useUsersList()?.data;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (!showFilter) {
      setFilterItemsCount(0);
      for (const key in appliedFilters) {
        if (Array.isArray(appliedFilters[key])) {
          setFilterItemsCount((prevFilterItemsCount) => {
            if (key === "date" && appliedFilters[key].length > 0)
              return (prevFilterItemsCount += 1);
            return (prevFilterItemsCount += appliedFilters[key].length);
          });
        }
      }
    }
  }, [appliedFilters, showFilter]);

  useEffect(() => {
    if (analyzersList) setIsLoading(false);
  }, [analyzersList]);

  return (
    <div className="main__router__container">
      {showFilter && (
        <Filter
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          isOpen={showFilter}
          setIsOpen={setShowFilter}
          onSubmitFilters={() => {
            setCurrentPage(1);
            // fetchAnalysisList(1);
          }}
          // totalResults={listItems?.meta?.total}
        />
      )}

      <div className="main__router__container__list-box-container">
        <button
          className="filterBtn mb-1"
          //  onClick={() => setShowFilter(true)}
        >
          <img src={filterSvg} alt="filter icon" />
          Filter
          {filterItemsCount > 0 && <span>{filterItemsCount} Items</span>}
          <ArrowLineRightSvg />
        </button>

        {!isLoading && analyzersList?.length > 0 ? (
          <div>
            <div className="list-table-cstm-one scrollbar-style scrollbar-style-thumb">
              <table className="list-table-cstm-one__content-table">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Technical</th>
                    <th>Fundamental</th>
                    <th>On-Chain</th>
                    <th>Win rate</th>
                    <th>Detail</th>
                  </tr>
                </thead>

                <tbody>
                  {analyzersList?.map((item, index) => (
                    <tr className=" h-14" key={index}>
                      <td className="">
                        {item?.first_name} {item?.last_name}
                      </td>

                      <td className="">
                        {item?.technical.setup_count +
                          item.technical.analytic_count}
                      </td>

                      <td>{item?.fundamental}</td>
                      <td>{item?.on_chain}</td>

                      <td className="">
                        {item?.win_rate !== null
                          ? `${parseInt(Number(item?.win_rate) * 100)}${
                              item?.win_rate !== 0 && "%"
                            }`
                          : "-"}
                      </td>

                      <td className="list-table-cstm-one__content-table__details-arrow">
                        <NavLink
                          className="flex justify-center items-center"
                          to={`/userList/userProfile?id=${item?.uuid}`}
                        >
                          <ArrowRightSvg />
                        </NavLink>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* {analyzersList?.meta?.total > 10 && (
              <div className="pagination-one">
                <Pagination
                  current={currentPage}
                  total={analyzersList?.meta?.total}
                  pageSize={10}
                  onChange={handlePageChange}
                  showSizeChanger={false}
                  prevIcon={<ArrowLeftSvg />}
                  nextIcon={<ArrowRightSvg />}
                />
              </div>
            )} */}
          </div>
        ) : !isLoading ? (
          <div className="empty-analysis">
            <h4 className="empty-analysis__title">No results!</h4>
          </div>
        ) : (
          <Loading size="large" />
        )}
      </div>
    </div>
  );
};

export default UserList;
