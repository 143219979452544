export const statusesTheme = (status) => {
  switch (status) {
    case "WIN":
    case "Target. 1":
    case "Terminate Target":
      return "bg-twilight-20 border-twilight-50 text-twilight-50";

    case "ONGOING":
    case "Entry. 1":
    case "Terminate":
      return "bg-sky-10 border-sky-40 text-sky-40";
    case "LOSE":
    case "Terminate Stop":
    case "Stop. 2":
      return "bg-infrared-20 border-infrared-50 text-infrared-50";
    default:
      return "bg-storm-10 border-storm-40 text-storm-40";
  }
};
