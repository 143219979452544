import React, { useRef, useState, useEffect } from "react";

import { PairsSymbol } from "~/components/child/Symbols";
import NotificationPointsSvg from "~/components/child/NotificationPointsSvg";

import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";
import { usersProfileColor } from "utils/usersProfileColor";

import NotificationSvg from "~/assets/icon/svgJsx/NotificationSvg";
import ArrowDownSvg from "~/assets/icon/svgJsx/ArrowDownSvg";
import ArrowTopSvg from "~/assets/icon/svgJsx/ArrowTopSvg";

import MoreOneSvg from "~/assets/icon/svgJsx/MoreOneSvg";
import CopySvg from "~/assets/icon/svgJsx/CopySvg";

export const getStatusColor = (status) => {
  switch (status) {
    case "disable": {
      return "text-[#FF9500]";
    }
    case "active":
      return "text-leaf-60";
    case "inactive":
      return "text-infrared-key";

    default:
      break;
  }
};

export const Item = ({
  slValues,
  tpValues,
  epValues,
  item,
  showMoreActionsTooltipId,
  onMoreActionsClick,
  setPopUpIsOpen,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };
  const paragraph = useRef();
  const submenuRef = useRef(null);
  const status = item.is_disabled
    ? "disable"
    : item.is_active === "1"
    ? "active"
    : "inactive";

  const validationDate = new Date(item.validation_date);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  validationDate.setHours(0, 0, 0, 0);

  const isValid = !(validationDate < today);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (submenuRef.current && !submenuRef.current.contains(event.target)) {
        onMoreActionsClick(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [submenuRef]);

  return (
    <div
      key={item.uuid}
      className="flex flex-col p-3.5 gap-4 bg-base rounded-lg"
    >
      <div className="flex flex-col gap-3">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center gap-2 lg:gap-4">
            <PairsSymbol
              pair={item?.pair?.pair}
              image={item?.pair?.image}
              className="text-storm-80 font-robotoMed mr-2"
            />
            <span
              className={`py-1.5 px-2.5 rounded-2xl text-sm font-normal font-roboto ${
                item?.position === "LONG"
                  ? " bg-twilight-30 text-twilight-70"
                  : " text-infrared-40 bg-infrared-10"
              }`}
            >
              {changeWordFormatToTitleCase(item?.position)}
            </span>
          </div>
          <div className="flex flex-row items-center gap-2 lg:gap-4 font-roboto text-sm">
            <p className="flex items-center text-xs lg:text-sm gap-1">
              <span className=" text-sky-70">Validation date</span>
              <span className={getStatusColor(status)}>
                {item.validation_date}
              </span>
            </p>
            <p className="flex text-xs lg:text-sm items-center gap-1 text-oil-key">
              <span>Creation date</span>
              <span>{item.created_at}</span>
            </p>
            <div
              className={` font-robotoMed flex items-center gap-2 ${getStatusColor(
                status
              )}`}
            >
              <NotificationSvg
                color={
                  status === "disable"
                    ? "#FF9500"
                    : status === "active"
                    ? "#349D57"
                    : "#df2040"
                }
              />

              <span>{changeWordFormatToTitleCase(status)}</span>
            </div>
            {status !== "inactive" && (
              <div className="relative flex">
                <div className="relative flex">
                  {showMoreActionsTooltipId === item.uuid && (
                    <div
                      ref={submenuRef}
                      className="absolute w-32 top-[20%] right-[60%] bg-white p-2 z-10 shadow-[0_1px_8px_0_rgba(0,0,0,.122)] py-2 px-3 bg-storm-light rounded-lg"
                    >
                      <button
                        type="button"
                        onClick={() => {
                          if (isValid) setPopUpIsOpen();
                          onMoreActionsClick(null);
                        }}
                        className={`text-storm-40 flex text-xs font-roboto text-left w-full leading-6 ${
                          !isValid ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                      >
                        {status === "disable"
                          ? "Enable"
                          : status === "active" && "Disable"}{" "}
                        notification
                      </button>
                    </div>
                  )}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onMoreActionsClick(item.uuid);
                    }}
                  >
                    <MoreOneSvg />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex font-roboto flex-row items-center gap-4 1.5xl:gap-0 justify-between">
          <div className="ml-2 flex items-center justify-between w-full 1.5xl:w-[74%]">
            {epValues !== null && (
              <div className="flex items-center justify-center gap-2 lg:gap-4">
                <span className="font-robotoMed text-sky-key">EP</span>
                <span className=" text-sm xl:text-base text-oil-60 xl:text-oil-60 flex items-center justify-center gap-2">
                  {epValues?.length > 1 ? "multi numbs" : epValues[0]?.amount}
                </span>
                <NotificationPointsSvg status={epValues[0]?.is_goes_over} />
              </div>
            )}

            {slValues !== null && (
              <div className=" flex items-center justify-center gap-2 lg:gap-4">
                <span className="font-robotoMed text-infrared-key">SL</span>
                <span className="text-sm xl:text-base text-oil-60 xl:text-oil-60 flex items-center justify-center gap-2">
                  {slValues[0]?.amount}
                </span>

                <NotificationPointsSvg status={slValues[0]?.is_goes_over} />
              </div>
            )}

            {tpValues !== null && (
              <div className=" flex items-center justify-center gap-2 lg:gap-4">
                <span className="font-robotoMed text-leaf-50">TP</span>
                <span className="text-sm xl:text-base text-oil-60 xl:text-oil-60 flex items-center justify-center gap-2">
                  <span>
                    {tpValues?.length > 1 ? "multi numbs" : tpValues[0].amount}
                  </span>

                  {tpValues?.find(
                    (conditionItem) =>
                      conditionItem?.is_goes_over === "goes under"
                  ) ? (
                    <NotificationPointsSvg status="goes under" />
                  ) : (
                    ""
                  )}

                  {tpValues?.find(
                    (conditionItem) =>
                      conditionItem?.is_goes_over === "goes over"
                  ) ? (
                    <NotificationPointsSvg status="goes over" />
                  ) : (
                    ""
                  )}
                  {tpValues?.length === 1 && tpValues[0]?.rr !== null && (
                    <div className="btn-ocean-cstm">RR {tpValues[0]?.rr}</div>
                  )}
                </span>
              </div>
            )}
          </div>
          <div className="flex items-center gap-2">
            <span className=" py-1.5 px-2.5 text-xs text-storm-50 bg-storm-20 rounded-3xl">
              {item.notification_type}
            </span>

            <button
              onClick={toggleDetails}
              className={`p-2.5 ${
                showDetails ? " bg-sky-key text-storm-10" : ""
              } w-4 flex justify-center items-center h-4 border border-sky-key cursor-pointer rounded-full`}
            >
              {showDetails ? (
                <ArrowTopSvg />
              ) : (
                <ArrowDownSvg color={showDetails ? "#ffffff" : "#007bff"} />
              )}
            </button>
          </div>
        </div>
      </div>

      {showDetails && (
        <div
          className={` ${
            tpValues?.length > 1 || item.description || epValues?.length > 1
              ? "border-t pt-4"
              : ""
          } border-[#3535350f] flex flex-col gap-4`}
        >
          {epValues?.length > 1 && (
            <div className="w-full grid grid-cols-2 1.5xl:grid-cols-3	 gap-4">
              {epValues.map((itemEp, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between 1.5xl:justify-around gap-2.5 1.5xl:gap-6 "
                >
                  <span className=" text-sky-key">EP. {index + 1}</span>
                  <span className="text-oil-60 xl:text-oil-60 font-roboto text-sm xl:text-base">
                    {itemEp?.amount}
                  </span>
                  <NotificationPointsSvg status={itemEp?.is_goes_over} />
                    <div className="min-w-10" />
                  {(index + 1) % 3 !== 0 && (
                    <span className="text-[#3535350f] hidden 1.5xl:flex h-8">
                      |
                    </span>
                  )}
                  {(index + 1) % 2 !== 0 && (
                    <span className="text-[#3535350f] flex 1.5xl:hidden h-8">
                      |
                    </span>
                  )}
                </div>
              ))}
            </div>
          )}

          {tpValues?.length > 1 && (
            <div className="w-full grid grid-cols-2 1.5xl:grid-cols-3	 gap-4">
              {tpValues.map((itemTp, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between 1.5xl:justify-around gap-2.5 1.5xl:gap-6 "
                >
                  <span className=" text-leaf-50">TP. {index + 1}</span>
                  <span className="text-oil-60 xl:text-oil-60 font-roboto text-sm xl:text-base">
                    {itemTp?.amount}
                  </span>
                  <NotificationPointsSvg status={itemTp?.is_goes_over} />

                  {itemTp?.rr !== null && (
                    <div className="btn-ocean-cstm">RR {itemTp?.rr}</div>
                  )}
                  {(index + 1) % 3 !== 0 && (
                    <span className="text-[#3535350f] hidden 1.5xl:flex h-8">
                      |
                    </span>
                  )}
                  {(index + 1) % 2 !== 0 && (
                    <span className="text-[#3535350f] flex 1.5xl:hidden h-8">
                      |
                    </span>
                  )}
                </div>
              ))}
            </div>
          )}

          {item.description && (
            <div className="py-3 px-4 items-start flex bg-storm-10 rounded-lg gap-1 ">
              <CopySvg className="w-5 h-5" />

              <p
                className="text-sm font-yekanBakh py-1 px-2 text-right text-wrap font-light text-oil-60"
                ref={paragraph}
              >
                {item.description
                  ? item.description?.trim()
                  : "هیچ توضیحاتی نوشته نشده است ..."}
              </p>
            </div>
          )}
          {item.receivers.length > 0 && (
            <div className="border-t text-oil-key font-normal text-xs font-roboto border-[#3535350f] flex items-center gap-6 pt-4">
              <span className=" min-w-fit">Send to</span>
              <div className="flex flex-wrap items-center gap-5">
                {item.receivers.map((receiver, index) => {
                  const color = usersProfileColor(index);
                  return (
                    <div
                      key={receiver.uuid}
                      className="flex gap-1 items-center"
                    >
                      <span
                        className={`w-6 h-6 items-center text-black ${color} justify-center text-base rounded-full outline outline-storm-10 flex`}
                      >
                        {receiver.first_name.charAt(0)?.toUpperCase()}
                      </span>
                      <span className="font-light">
                        {receiver.first_name} {receiver.last_name}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
