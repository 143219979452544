import { callRestPost } from "../../utils/callRestApi";

export const authApi = {
  login: async (body) => {
    const result = { data: null, error: null };
    await callRestPost("/auth/login", body, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        result.error = err;
      });

    return result;
  },

  refreshToken: async (headers) => {
    const result = { data: null, error: null };
    await callRestPost("/auth/refresh-token", null, headers)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        result.error = err;
      });

    return result;
  },
  logout: async (body) => {
    const result = { data: null, error: null };
    await callRestPost("/auth/logout", body, null)
      .then((res) => {
        if (res.status === 204) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        result.error = err;
      });

    return result;
  },
};
