import React from "react";

const InputBase = ({ title, value, setValue, children, className }) => {
  return (
    <div
      className={` relative border border-storm-20 rounded-2xl font-bold font-roboto cursor-pointer ${className}`}
    >
      {title && (
        <div className=" text-oil-60 text-[0.625rem] absolute left-3 -top-2 px-0 bg-transparent">
          {title}
        </div>
      )}

      <div className=" pr-3 pl-1 py-1 text-xs">
        {children ? (
          children
        ) : (
          <input
            className="p-3 w-[97%] border-none focus:border-none focus-visible:border-none outline-none text-right font-yekanBakh text-xs text-oil-dark"
            value={value}
            onChange={setValue}
          />
        )}
      </div>
    </div>
  );
};

export default InputBase;
