import React, { useEffect, useRef } from "react";

import { widget } from "~/charting_library";
import { WidgetCreator } from "../../../utils/classes/widgetCreator";
import { convertIntervalToTradingViewFormat } from "../../../utils/converters/convertTimeFrameToTradingViewFormat";
import { priceTypeDetermine } from "../../../utils/determinators/priceTypeDetermine";
import { createTradeBoundariesWidgetTimeRange } from "../../../utils/creators/createTradeBoundariesWidgetTimeRange";
import { trimTrailingCharacter } from "../../../utils/helpers/tools/trimTrailingCharacter";
import { createPairDataConfig } from "../../../utils/creators/createPairDataConfig";
import { setIntervalChartSource } from "../../../utils/chartSourceModifiers/setIntervalChartSource";
import { createChartConfig } from "../../../config/createChartConfig";

import "../../index.css";

export const TVChartContainer = ({
  ep,
  sl,
  tp,
  pairDetail,
  initInterval,
  baseDateType,
  step,
  techChartData,
  setTechChartData,
  theme,
  setEditDataHasChanged,
}) => {
  const chartContainerRef = useRef();
  const { baseChartConfig, specificConfigs } = createChartConfig("technical", {
    timezone: "Asia/Tehran",
  });

  const interval = convertIntervalToTradingViewFormat(initInterval);

  const { baseSymbol, quoteSymbol, exchange } = pairDetail;
  const chartData = setIntervalChartSource(techChartData, interval);
  const { pair, dataFeed: datafeedUrl } = createPairDataConfig(
    baseSymbol,
    quoteSymbol,
    exchange
  );

  useEffect(() => {
    const widgetOptions = {
      ...baseChartConfig?.defaultProps,
      container: chartContainerRef.current,
      datafeed: baseChartConfig?.createDatafeed(datafeedUrl),
      disabled_features: specificConfigs?.create?.disabledFeatures,
      enabled_features: specificConfigs?.create?.enabledFeatures,
      theme: theme,
      symbol: pair,
      interval: interval,
      saved_data: chartData,
    };

    const tvWidget = new widget(widgetOptions);
    const createWidget = new WidgetCreator(tvWidget);

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        tvWidget.save((data) => {
          setTechChartData(data);
        });
        tvWidget.subscribe("undo_redo_state_changed", () => {
          tvWidget.save((data) => {
            setTechChartData(data);
            setEditDataHasChanged(true);
          });
        });

        if (ep && ep.length > 0) {
          for (const item of ep) {
            let initPrice = item?.value;
            let price = trimTrailingCharacter(initPrice, "-");

            if (price !== "") {
              const { type } = priceTypeDetermine(price) || {};
              if (type === "single") {
                createWidget.trendLine(
                  price,
                  createTradeBoundariesWidgetTimeRange(
                    interval,
                    null,
                    baseDateType
                  ),
                  "ep"
                );
              }
              if (type === "range") {
                createWidget.rectangle(
                  price.split("-"),
                  createTradeBoundariesWidgetTimeRange(
                    interval,
                    null,
                    baseDateType
                  ),
                  "ep"
                );
              }
            }
          }
        }

        if (sl && sl.length > 0) {
          for (const item of sl) {
            let initPrice = item?.value;
            let price = trimTrailingCharacter(initPrice, "-");

            if (price !== "") {
              const { type } = priceTypeDetermine(price) || {};
              if (type === "single") {
                createWidget.trendLine(
                  price,
                  createTradeBoundariesWidgetTimeRange(
                    interval,
                    null,
                    baseDateType
                  ),
                  "sl"
                );
              }
              if (type === "range") {
                createWidget.rectangle(
                  price.split("-"),
                  createTradeBoundariesWidgetTimeRange(
                    interval,
                    null,
                    baseDateType
                  ),
                  "sl"
                );
              }
            }
          }
        }

        if (tp && tp.length > 0) {
          for (const item of tp) {
            let initPrice = item?.value;
            let price = trimTrailingCharacter(initPrice, "-");

            if (price !== "") {
              const { type } = priceTypeDetermine(price) || {};
              if (type === "single") {
                createWidget.trendLine(
                  price,
                  createTradeBoundariesWidgetTimeRange(
                    interval,
                    null,
                    baseDateType
                  ),
                  "tp"
                );
              }
              if (type === "range") {
                createWidget.rectangle(
                  price.split("-"),
                  createTradeBoundariesWidgetTimeRange(
                    interval,
                    null,
                    baseDateType
                  ),
                  "tp"
                );
              }
            }
          }
        }
      });
    });

    return () => {
      tvWidget.remove();
    };
  }, [step, theme]);

  return <div ref={chartContainerRef} className={"TVChartContainer"} />;
};

export default TVChartContainer;
