import React, { useEffect, useRef, useState } from "react";

import { widget } from "../../../../../../charting_library";
import { WidgetCreator } from "../../../utils/classes/widgetCreator";
import { convertIntervalToTradingViewFormat } from "../../../utils/converters/convertTimeFrameToTradingViewFormat";
import { createChartConfig } from "~/components/child/tradingViewMainChart/config/createChartConfig.js";
import { createTradeBoundariesWidgetTimeRange } from "../../../utils/creators/createTradeBoundariesWidgetTimeRange";
import { createChartTimeFrame } from "../../../utils/creators/createChartTimeFrame";
import { combineAllChartsSources } from "../../../utils/chartSourceModifiers/combineAllChartsSources";
import { setIntervalChartSource } from "../../../utils/chartSourceModifiers/setIntervalChartSource";
import { convertDateToUnixTime } from "../../../utils/converters/convertDateToUnixTime";
import { createPairDataConfig } from "../../../utils/creators/createPairDataConfig";
import { tvColors } from "../../../utils/helpers/tools/tvColors";
import { plotVerticalLineAtCreationTime } from "../../../utils/helpers/plotting/plotVerticalLineAtCreationTime";
import { setThemeChartSource } from "../../../utils/chartSourceModifiers/setThemeChartSource";
import { convertChartTimeZone } from "../../../utils/converters/convertChartTimeZone";
import { createWidgetLabelOverrides } from "../../../utils/creators/createWidgetLabelOverrides";
import { Checkbox } from "../../../../ui/Checkbox";

import useLocalStorage from "use-local-storage";

import "../../index.css";


export const TVChartContainer = React.memo(
  ({ analysisDetailsProp, baseDateType, selectedAnalysersIds, theme }) => {
 
    const chartContainerRef = useRef();
    const { baseChartConfig, specificConfigs } = createChartConfig("heatmap");

    const analysisDetails = analysisDetailsProp?.data;

    const {
      base_symbol: baseSymbol,
      quote_symbol: quoteSymbol,
      exchange,
    } = analysisDetailsProp?.data?.pair;
    const { pair, dataFeed: datafeedUrl } = createPairDataConfig(
      baseSymbol,
      quoteSymbol,
      exchange
    );

    const createdAt = analysisDetails?.date;

    const oldestAnalysisDate =
      analysisDetails?.analysis_data?.oldest_analysis_date;

    const technicalList = analysisDetails?.analysis_data?.technical.filter(
      (item) => {
        return selectedAnalysersIds?.includes(item?.analysis?.uuid);
      }
    );

    const fundamentalList = analysisDetails?.analysis_data?.fundamental.filter(
      (item) => {
        return selectedAnalysersIds?.includes(item?.analysis?.uuid);
      }
    );

    const initAllChartData = technicalList.concat(fundamentalList);

    const getIntervalProp = convertIntervalToTradingViewFormat(
      analysisDetails?.timeframe?.time_frame
    );
    const [interval, setInterval] = useState(getIntervalProp);
    const [userInterval, setUserInterval] = useLocalStorage("userInterval", "");

    const [showWidgets, setShowWidgets] = useState(true);
    const [showAnalyserName, setShowAnalyserName] = useState(false);
    const [tempDynamicRange, setTempDynamicRange] = useState(false);

    const handleShowWidgets = () => {
      setShowWidgets(!showWidgets);
    };
    const handleShowAnalyserName = () => {
      setShowAnalyserName(!showAnalyserName);
    };
    const handleTempDynamicRange = () => {
      setTempDynamicRange(!tempDynamicRange);
    };
    

    useEffect(() => {
      setUserInterval("");
    }, [selectedAnalysersIds]);

    useEffect(() => {
      if (
        fundamentalList &&
        fundamentalList.length > 0 &&
        userInterval === ""
      ) {
        setInterval("D");
      } else if (
        technicalList &&
        technicalList.length > 0 &&
        userInterval === ""
      ) {
        setInterval(getIntervalProp);
      } else if (userInterval) {
        setInterval(userInterval);
      } else {
        setInterval(convertIntervalToTradingViewFormat(getIntervalProp));
      }
    }, [
      technicalList,
      fundamentalList,
      interval,
      userInterval,
      analysisDetails,
    ]);

    const { timeFrom: timeFrameLoadChartFrom, timeTo: timeFrameLoadChartTo } =
      createChartTimeFrame(interval, oldestAnalysisDate.split(" ")[0]);

    let allChartData = [];

    initAllChartData &&
      initAllChartData.forEach((item) => {
        item.chart_data = item.chart_data === "null" ? null : item.chart_data;
        if (item.chart_data) {
          allChartData.push(JSON.parse(item.chart_data));
        }
      });

    let chartData =
      allChartData.length > 0 ? combineAllChartsSources(allChartData) : null;

    // --- Select the appropriate interval based on user input, analysis type, and default settings
    let selectedInterval = interval;

    if (userInterval) {
      selectedInterval = userInterval;
    } else if (
      fundamentalList &&
      fundamentalList?.length > 0 &&
      userInterval === ""
    ) {
      selectedInterval = "D";
    } else if (
      technicalList &&
      technicalList?.length > 0 &&
      userInterval === ""
    ) {
      selectedInterval = interval;
    }
    // --- END
    let finalHeatMapChartData = setIntervalChartSource(
      chartData,
      selectedInterval
    );

    const modifyChartDataTheme = setThemeChartSource(
      finalHeatMapChartData,
      theme
    );
    const finalChartData = convertChartTimeZone(modifyChartDataTheme, 1); 

    useEffect(() => {
      const widgetOptions = {
        ...baseChartConfig?.defaultProps,
        container: chartContainerRef.current,
        datafeed: baseChartConfig?.createDatafeed(datafeedUrl),
        disabled_features: specificConfigs?.disabledFeatures,
        enabled_features: specificConfigs?.enabledFeatures,
        theme: theme,
        symbol: pair,
        interval: interval,
        timeframe: { from: timeFrameLoadChartFrom, to: timeFrameLoadChartTo },
        saved_data: finalChartData,
      };

      const tvWidget = new widget(widgetOptions);
      const createWidget = new WidgetCreator(tvWidget);

      tvWidget.onChartReady(() => {
        tvWidget.headerReady().then(() => {
          technicalList &&
            technicalList.forEach((tech) => {
              tech?.details &&
                tech?.details.forEach((detail) => {
                  if (detail?.ep) {
                    detail?.ep &&
                      detail?.ep.length > 0 &&
                      detail?.ep.forEach((item) => {
                        const type =
                          item?.max_amount === 0 ? "single" : "range";

                        if (type === "single") {
                          createWidget.trendLine(
                            item?.base_amount.toString(), 
                            createTradeBoundariesWidgetTimeRange(
                              interval,
                              tech?.analysis?.created_at,
                              baseDateType,
                              tempDynamicRange
                            ),
                            "ep",
                            showAnalyserName &&
                              createWidgetLabelOverrides(
                                "trendLineHorzHeatMap",
                                {
                                  text: tech?.analysis?.user_name,
                                  textcolor: tvColors?.blue,
                                }
                              )
                          );
                        }
                        if (type === "range") {
                          createWidget.rectangle(
                            [
                              item?.base_amount.toString(),
                              item?.max_amount.toString(),
                            ],
                            createTradeBoundariesWidgetTimeRange(
                              interval,
                              tech?.analysis?.created_at,
                              baseDateType,
                              tempDynamicRange
                            ),
                            "ep",
                            showAnalyserName &&
                              createWidgetLabelOverrides(
                                "rectangleHorzHeatMap",
                                {
                                  text: tech?.analysis?.user_name,
                                  textColor: tvColors?.blue,
                                }
                              )
                          );
                        }
                      });
                  }
                  if (detail?.tp) {
                    detail?.tp &&
                      detail?.tp.length > 0 &&
                      detail?.tp.forEach((item) => {
                        const type =
                          item?.max_amount === 0 ? "single" : "range";

                        if (type === "single") {
                          createWidget.trendLine(
                            item?.base_amount.toString(),
                            createTradeBoundariesWidgetTimeRange(
                              interval,
                              tech?.analysis?.created_at,
                              baseDateType,
                              tempDynamicRange
                            ),
                            "tp",
                            showAnalyserName &&
                              createWidgetLabelOverrides(
                                "trendLineHorzHeatMap",
                                {
                                  text: tech?.analysis?.user_name,
                                  textcolor: tvColors?.green,
                                }
                              )
                          );
                        }
                        if (type === "range") {
                          createWidget.rectangle(
                            [
                              item?.base_amount.toString(),
                              item?.max_amount.toString(),
                            ],
                            createTradeBoundariesWidgetTimeRange(
                              interval,
                              tech?.analysis?.created_at,
                              baseDateType,
                              tempDynamicRange
                            ),
                            "tp",
                            showAnalyserName &&
                              createWidgetLabelOverrides(
                                "rectangleHorzHeatMap",
                                {
                                  text: tech?.analysis?.user_name,
                                  textColor: tvColors?.green,
                                }
                              )
                          );
                        }
                      });
                  }
                  if (detail?.sl) {
                    detail?.sl &&
                      detail?.sl.length > 0 &&
                      detail?.sl.forEach((item) => {
                        const type =
                          item?.max_amount === 0 ? "single" : "range";

                        if (type === "single") {
                          createWidget.trendLine(
                            item?.base_amount.toString(),
                            createTradeBoundariesWidgetTimeRange(
                              interval,
                              tech?.analysis?.created_at,
                              baseDateType,
                              tempDynamicRange
                            ),
                            "sl",
                            showAnalyserName &&
                              createWidgetLabelOverrides(
                                "trendLineHorzHeatMap",
                                {
                                  text: tech?.analysis?.user_name,
                                  textcolor: tvColors?.red,
                                }
                              )
                          );
                        }
                        if (type === "range") {
                          createWidget.rectangle(
                            [
                              item?.base_amount.toString(),
                              item?.max_amount.toString(),
                            ],
                            createTradeBoundariesWidgetTimeRange(
                              interval,
                              tech?.analysis?.created_at,
                              baseDateType,
                              tempDynamicRange
                            ),
                            "sl",
                            showAnalyserName &&
                              createWidgetLabelOverrides(
                                "rectangleHorzHeatMap",
                                {
                                  text: tech?.analysis?.user_name,
                                  textColor: tvColors?.red,
                                }
                              )
                          );
                        }
                      });
                  }
                });
            });

          fundamentalList &&
            fundamentalList.forEach((fund) => {
              fund?.details &&
                fund?.details.length > 0 &&
                fund?.details.forEach((funDetai) => {
                  let initDate = funDetai?.analysis_date.split(" ");
                  let date = initDate[0];
                  const dateToUnixTime = convertDateToUnixTime(1, date);
                  createWidget.verticalLine(
                    dateToUnixTime,
                    "fundamentalDatePoint",
                    showAnalyserName &&
                      createWidgetLabelOverrides("verticalLineHeatMap", {
                        text: fund?.analysis?.user_name,
                        textcolor: tvColors?.purple,
                      })
                  );
                });
            });

          // show created at by vertical_line => style :dashed
          plotVerticalLineAtCreationTime(createdAt, createWidget);

          tvWidget
            .activeChart()
            .onIntervalChanged()
            .subscribe(null, (interval) => {
              setInterval(convertIntervalToTradingViewFormat(interval));
              setUserInterval(convertIntervalToTradingViewFormat(interval));
            });

          if (!showWidgets) {
            tvWidget.activeChart().removeAllShapes();
            tvWidget.activeChart().removeAllStudies();
          }
        });
      });

      return () => {
        tvWidget.remove();
      };
    }, [
      technicalList,
      fundamentalList,
      interval,
      userInterval,
      showWidgets,
      showAnalyserName,
      tempDynamicRange
    ]);

    return (
      <div className="flex flex-col w-full">
        <div className="flex mb-3 gap-5 text-sky-70 font-roboto font-bold">
          <Checkbox
            id={"showWidgets"}
            label={"Show widgets"}
            checked={showWidgets}
            onChange={handleShowWidgets}
          />
          <Checkbox
            id={"showNames"}
            label={"Show names"}
            checked={showAnalyserName}
            onChange={handleShowAnalyserName}
          />

          <Checkbox
            id={"dynamicRange"}
            label={"Dynamic range"}
            checked={tempDynamicRange}
            onChange={handleTempDynamicRange}
          />
        </div>
        <div ref={chartContainerRef} className={"TVChartContainer"} />
      </div>
    );
  }
);
