import React from "react";
import { truncate } from "../../../../utils/truncate";
import RemoveSvg from "../../../../assets/icon/svgJsx/RemoveSvg";

const Preview = ({ images, onRemove, onSetAsPrimary, isEditable = true }) => {
  return (
    <div className="preview">
      {images.map((item) => (
        <div key={item.id} className="preview__item">
          <div className="preview__item__info">
            <img className="preview__item__info__image" src={item.src} alt="" />
            <span className="preview__item__info__name">
              {truncate(item.name, 18)}
            </span>
          </div>
          {isEditable ? (
            <div className="preview__item__actions">
              <button className="preview__item__actions__setPrimary">
                <input
                  onChange={() => {
                    if (onSetAsPrimary) onSetAsPrimary(item.id);
                  }}
                  type="radio"
                  name="active-picture"
                  checked={item.active}
                />
                <label>Set as primary</label>
              </button>

              <button
                className="preview__item__actions__delete"
                onClick={() => {
                  if (onRemove) onRemove(item.id);
                }}
              >
                <RemoveSvg />
              </button>
            </div>
          ) : item.active ? (
            <span className="preview__item__primaryTitle">Primary</span>
          ) : (
            ""
          )}
        </div>
      ))}
    </div>
  );
};

export default Preview;
