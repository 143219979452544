import React from "react";

import DatePicker from "react-multi-date-picker";
import CustomInput from "./CustomInput";
import ArrowLeft from "../../../../assets/icon/svgJsx/ArrowLeftSvg";
import ArrowRight from "../../../../assets/icon/svgJsx/ArrowRightSvg";

function DatePickerCustm({
  emitDatePicker,
  date,
  inputLabel,
  forcible,
  position,
  range = false,
  className,
  minDate,
  maxDate,
}) {
  const weekDays = ["Sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  return (
    <div className={`date-picker-one ${className}`}>
      <DatePicker
        className="custom-container-one color-cstm"
        renderButton={(direction, handleClick) => (
          <i
            onClick={handleClick}
            style={{
              cursor: "pointer",
              padding: "10px",
            }}
          >
            {direction === "right" ? <ArrowRight /> : <ArrowLeft />}
          </i>
        )}
        format="MM/DD/YYYY"
        popperPlacement={range ? "right" : ""}
        popperProps={{
          positionFixed: range ? true : false, // use this to make the popper position: fixed
        }}
        calendarPosition={
          position ? position : !range ? "right-top" : undefined
        }
        monthYearSeparator=" "
        showOtherDays
        render={
          <CustomInput
            placeholder={range ? "MM/DD/YYYY - MM/DD/YYYY" : "MM/DD/YYYY"}
            forcible={forcible}
            label={inputLabel}
          />
        }
        onChange={(value) => {
          emitDatePicker(value);
        }}
        arrow={false}
        weekDays={weekDays}
        value={date}
        range={range}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
}

export default DatePickerCustm;
