import React from "react";
import AnalysisHeader from "~/components/child/AnalysisHeader";
import FundamentalEntry from "~/components/child/FundamentalEntry";
import ArrayImg from "~/components/child/ui/imageUpload/Preview";

const Preview = ({ analysisValues }) => {
  return (
    <div className="fundamental-preview__box">
      <div className="fundamental-preview__box__title">
        your analysis Preview
      </div>
      <AnalysisHeader pairs={analysisValues.new.pair} type="Fundamental" />
      <p className="fundamental-preview__box__description">
        {analysisValues.fundamental.description}
      </p>
      <div className="fundamental-preview__box__entries">
        <div className="fundamental-preview__box__entries__title">
          <span>your entries</span>
        </div>

        {analysisValues.fundamental.entries.length > 0 &&
          analysisValues.fundamental.entries?.map((item) => (
            <div key={item?.id}>
              <FundamentalEntry
                id={item?.id}
                label={item?.title}
                date={item.date}
                description={item?.tooltip}
              />
            </div>
          ))}
      </div>
      <div className="fundamental-preview__box__images">
        <ArrayImg
          isEditable={false}
          images={analysisValues.fundamental?.images}
        />
      </div>
    </div>
  );
};

export default Preview;
