import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Loading } from "~/components/child/ui/Loading";
import Accordion from "~/components/child/ui/Accordion";
import Modal from "~/components/child/ui/Modal";
import { HeatmapDetails } from "~/components/child/heatmapDetails";
import { RightNav } from "~/components/child/RightNav";
import Comment from "~/components/child/Comment";
import { PrivateNote } from "~/components/child/PrivateNote";

import Analyzers from "./Analyzers";
import Alerts from "./alerts/Alerts";
import CreateNewNotification from "../../notification/CreateNew";

import { useHeatMapDetails } from "~/utils/hooks";

import NotificationSvg from "~/assets/icon/svgJsx/NotificationSvg";

const HeatMapView = ({ permissions }) => {
  const { id } = useParams();

  const { details, chartDetails, isLoading } = useHeatMapDetails(id);

  const [totalValuesOfRightNav, setTotalValuesOfRightNav] = useState({
    privateNotes: 0,
    analyzers: 0,
    alerts: 0,
  });

  const [selectedAnalyzers, setSelectedAnalyzers] = useState([]);
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);

  useEffect(() => {
    if (details?.users && !isLoading) {
      setTotalValuesOfRightNav((prev) => ({
        ...prev,
        analyzers: details.users.length,
      }));
      setSelectedAnalyzers((prev) => [...new Set([...prev, ...details.users])]);
    }
  }, [details?.users, isLoading]);

  return (
    <>
      {!isLoading && details?.heatmap ? (
        <>
          {popUpIsOpen && (
            <Modal className="py-3 px-[2px] w-[27%] max-w-none">
              <CreateNewNotification
                type="heatmap"
                id={details?.heatmap?.uuid}
                setPopUpIsOpen={setPopUpIsOpen}
                pair={details?.heatmap?.pair}
                heatMapPositionType={
                  details?.heatmap?.technical?.ep
                    ? details?.heatmap?.technical?.ep[0].position_type
                    : details?.heatmap?.technical?.sl
                    ? details?.heatmap?.technical?.sl[0].position_type
                    : details?.heatmap?.technical?.tp
                    ? details?.heatmap?.technical?.tp[0].position_type
                    : undefined
                }
              />
            </Modal>
          )}
          <div className="main__router__container">
            <div className="main__router__container__view-box-container scrollbar-style scrollbar-style-thumb">
              <HeatmapDetails
                id={id}
                selectedAnalyzers={selectedAnalyzers}
                permissions={permissions}
                details={details}
                chartDetails={chartDetails}
              />
              {(permissions?.new_comment || permissions?.comment_list) && (
                <Comment section="heatmap" permissions={permissions} id={id} />
              )}
            </div>

            <RightNav className="view-heatMap__right-nav">
              <Accordion
                className={{ root: "view-heatMap__right-nav__accordion" }}
                label={{
                  leftPart: (
                    <span>
                      Analyzers
                      {totalValuesOfRightNav?.analyzers > 0 && (
                        <span className="total">
                          ({totalValuesOfRightNav?.analyzers})
                        </span>
                      )}
                    </span>
                  ),
                }}
                openDefault={true}
              >
                <Analyzers
                  selected={selectedAnalyzers}
                  setSelected={setSelectedAnalyzers}
                  list={details?.users}
                />
              </Accordion>
              {(permissions?.new_notification ||
                permissions?.notifications_list) && (
                <Accordion
                  className={{ root: "view-heatMap__right-nav__accordion" }}
                  label={{
                    leftPart: (
                      <span>
                        Alerts
                        {totalValuesOfRightNav?.alerts > 0 && (
                          <span className="total">
                            ({totalValuesOfRightNav?.alerts})
                          </span>
                        )}
                      </span>
                    ),
                    rightPart: (
                      <>
                        {permissions?.new_notification && (
                          <button
                            onClick={() => setPopUpIsOpen(true)}
                            className="new-notification gap-1 flex items-center"
                          >
                            <NotificationSvg
                              color="#007bff"
                              width={16}
                              height={16}
                            />
                            New notification
                          </button>
                        )}
                      </>
                    ),
                  }}
                  openDefault={true}
                >
                  {permissions?.notifications_list && (
                    <Alerts
                      setTotalCount={setTotalValuesOfRightNav}
                      popUpIsOpen={popUpIsOpen}
                      id={details?.heatmap?.uuid}
                    />
                  )}
                </Accordion>
              )}
              {(permissions?.new_private_note ||
                permissions?.private_note_list) && (
                <Accordion
                  className={{ root: "view-heatMap__right-nav__accordion" }}
                  label={{
                    leftPart: (
                      <span>
                        Private Note
                        {totalValuesOfRightNav?.privateNotes > 0 && (
                          <span className="total">
                            ({totalValuesOfRightNav?.privateNotes})
                          </span>
                        )}
                      </span>
                    ),
                  }}
                  openDefault={true}
                >
                  <PrivateNote
                    permissions={permissions}
                    setTotalCount={(value) =>
                      setTotalValuesOfRightNav({
                        ...totalValuesOfRightNav,
                        privateNotes: value,
                      })
                    }
                    section="heatmap"
                    id={id}
                  />
                </Accordion>
              )}
            </RightNav>
          </div>
        </>
      ) : !isLoading ? (
        <div className="main__router__container">
          <div className="empty-heatMap">
            <h4 className="empty-heatMap__title">
              There isn't any heatMap with this Id!
            </h4>
          </div>
        </div>
      ) : (
        <Loading size="large" />
      )}
    </>
  );
};

export default HeatMapView;
