import { useState, useEffect } from "react";
import { usersApi } from "../../api/dashboard/usersApi";

export const useUsersList = (allUsers = false) => {
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    const fetchPairsList = async (allUsers) => {
      try {
        const data = await usersApi.list(allUsers);
        setUsersList(data);
      } catch (error) {
        console.error("Error fetching pairs data:", error);
      }
    };

    fetchPairsList(allUsers);
  }, []);

  return usersList.data;
};
