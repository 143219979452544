import { useState, useEffect } from "react";
import { heatMapApi } from "~/api/dashboard/heatMapApi";

export const useHeatMapDetails = (id) => {
  const [details, setDetails] = useState();
  const [chartDetails, setChartDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchHeatMapDetails = async () => {
      try {
        const result = await heatMapApi.single(id);

        setDetails(result.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching heatMap data:", error);
      }
    };

    const fetchHeatMapChartDetails = async () => {
      try {
        const result = await heatMapApi.singleChart(id);

        setChartDetails(result.data);
      } catch (error) {
        console.error("Error fetching heatMap data:", error);
      }
    };

    if (id) {
      fetchHeatMapDetails();
      fetchHeatMapChartDetails();
    }
  }, [id]);

  return { details, chartDetails, isLoading };
};
