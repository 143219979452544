import React from "react";

import Dropdown from "~/components/child/ui/DropDown";
import Switch from "~/components/child/ui/Switch";
import { AvatarGroup } from "./AvatarGroup";

import { useUsersList } from "~/utils/hooks";

export const ReceiveNotification = ({
  users,
  onClickHandler,
  switchIsOn,
  setSwitchIsOn,
  className,
}) => {
  return (
    <div
      className={`flex min-h-[3.25rem] w-[96%] items-start bg-base justify-between px-3 py-2.5 mb-1 border border-storm-20 rounded-2xl ${className}`}
    >
      <div className="flex flex-col gap-1 items-baseline">
        <div className="gap-2.5 flex items-center">
          <h5 className=" text-oil-60 text-sm">Receive notification</h5>
          {switchIsOn && <AvatarGroup avatars={users} maxVisible={4} />}
        </div>

        <button
          disabled={!switchIsOn}
          onClick={(e) => {
            e.stopPropagation();
            onClickHandler();
          }}
          className="font-roboto text-sm text-sky-40 disabled:opacity-60 disabled:cursor-not-allowed"
        >
          + Send to users
        </button>
      </div>
      <Switch setIsOn={(value) => setSwitchIsOn(value)} isOn={switchIsOn} />
    </div>
  );
};

export const NotificationModal = ({
  selectedUsers,
  onSelectUser,
  setShowNotificationModal,
}) => {
  const users = useUsersList()?.data;

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="px-4 flex flex-col gap-5"
    >
      <h3 className=" text-lg font-bold text-storm-80">Send to users</h3>
      <Dropdown
        alwaysOpen={true}
        bgColor="base"
        multipleValues={true}
        title="Users"
        placeholder={
          <span className="flex py-2.5 pl-3 text-sm text-storm-key ">
            Select users
          </span>
        }
        shouldShowSelectedValues={false}
        value={selectedUsers}
        optionsList={users?.map((user) => ({
          key: user.uuid,
          value: user?.first_name + " " + user?.last_name,
        }))}
        renderItem={(item) => (
          <p className="flex font-roboto text-base text-black items-center gap-1">
            {item.value}
          </p>
        )}
        onSelect={(item) => onSelectUser(item)}
        optionsListEndAdornment={
          <button
            className="py-2 w-full mt-2 text-sm font-normal text-sky-key font-roboto border border-sky-key rounded-xl disabled:opacity-60 disabled:cursor-not-allowed"
            onClick={() => setShowNotificationModal(false)}
          >
            OK
          </button>
        }
      />
    </div>
  );
};
