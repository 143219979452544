export const transformedFilterItems = (filters) => {
  const transformedFilters = {
    pairs: {},
    dates: {},
    analytics: {},
    analyzers: {},
    timeframes: {},
  };
  if (filters) {
    // Transform pairs
    filters?.pairs.forEach((pair, index) => {
      transformedFilters.pairs[index] = pair.key;
    });

    // Transform dates
    if (filters?.date.length > 0) {
      transformedFilters.dates.start = `${filters?.date[0]?.year}/0${filters?.date[0]?.month?.number}/${filters?.date[0]?.day}`;
      transformedFilters.dates.end = `${filters?.date[1]?.year}/0${filters?.date[1]?.month?.number}/${filters?.date[1]?.day}`;
    }
    // Transform analyzers
    filters?.analyzers?.forEach((analyzer, index) => {
      transformedFilters.analyzers[index] = analyzer.key;
    });
    // Transform analytics
    filters?.type?.forEach((type, index) => {
      transformedFilters.analytics[index] = type.key;
    });
    // Transform timeframes
    filters?.timeFrame?.forEach((timeFrame, index) => {
      transformedFilters.timeframes[index] = timeFrame.key;
    });
  }
  return transformedFilters;
};

export const transformedNotificationPoints = (notificationPoints) => {
  const transformedPoints = {
    points: {},
  };

  let index = 0;

  if (notificationPoints.entryPrice.price_range !== "") {
    transformedPoints.points[index] = {
      point_type: "ep",
      amount: notificationPoints.entryPrice.price_range,
      is_goes_over:
        notificationPoints.entryPrice.condition === "goes over" || false,
      rr: null,
    };
    index++;
  }

  if (notificationPoints.stopLoss.price_range !== "") {
    transformedPoints.points[index] = {
      point_type: "sl",
      amount: notificationPoints.stopLoss.price_range,
      is_goes_over:
        notificationPoints.stopLoss.condition === "goes over" || false,
      rr: null,
    };
    index++;
  }

  notificationPoints.takeProfits.forEach((item) => {
    if (item.price_range !== "") {
      transformedPoints.points[index] = {
        point_type: "tp",
        amount: item.value,
        is_goes_over: item.condition === "goes over" || false,
        rr: item.rr !== null ? Number(item.rr) : null,
      };
      index++;
    }
  });
  return transformedPoints;
};

export const transformedTechnicalAnalysisPoints = (
  entryPriceList,
  stopLossList,
  takeProfit
) => {
  const transformedPoints = {
    technical: {},
  };

  let index = 0;

  if (entryPriceList?.length > 0) {
    entryPriceList.forEach((item) => {
      transformedPoints.technical[index] = {
        point_type: "ep",
        base_amount: item.value.split("-")[0],
        max_amount: item.value.split("-")[1] ? item.value.split("-")[1] : null,
        point_description: item.desc,
      };
      index++;
    });
  }

  if (stopLossList?.length > 0) {
    stopLossList.forEach((item) => {
      transformedPoints.technical[index] = {
        point_type: "sl",
        base_amount: item.value.split("-")[0],
        max_amount: item.value.split("-")[1] ? item.value.split("-")[1] : null,
        point_description: item.desc,
      };
      index++;
    });
  }

  if (takeProfit?.length > 0) {
    takeProfit.forEach((item) => {
      transformedPoints.technical[index] = {
        point_type: "tp",
        base_amount: item.value.split("-")[0],
        max_amount: item.value.split("-")[1] ? item.value.split("-")[1] : null,
        point_description: item.desc,
        rr: item.rr,
      };
      index++;
    });
  }

  return transformedPoints;
};

export const transformedFundamentalAnalysisData = (
  formData,
  fundamentalData,
  pair,
  fundChartData
) => {
  formData.append("pair_key", pair);
  formData.append("analysis_type", "fundamental");
  formData.append("description", fundamentalData.description);
  formData.append("chart_data", JSON.stringify(fundChartData));

  if (fundamentalData.entries.length > 0) {
    fundamentalData.entries.forEach((item, index) => {
      formData.append(`fundamental[${index}][analysis_date]`, item.date);
      formData.append(`fundamental[${index}][title]`, item.title);
      formData.append(
        `fundamental[${index}][analysis_description]`,
        item.tooltip
      );
    });
  }
  transformedAnalysisImages(formData, fundamentalData.images, [
    "pictures",
    "file",
  ]);
};
export const transformedAnalysisImages = (formData, images, title) => {
  return images.forEach((item, index) => {
    if (item.file instanceof File) {
      formData.append(`${title[0]}[${index}][${title[1]}]`, item.file);
      formData.append(`${title[0]}[${index}][is_primary]`, item.active);
    } else {
      console.log("Error: item.file is not a File object.", item.file);
    }
  });
};

export const transformedNewHeatMapParams = (params) => {
  const transformedParams = {
    title: "",
    analytics: {},
    dates: {
      start: "",
      end: "",
    },
    description: "",
  };

  transformedParams.title = params.title;
  transformedParams.description = params.description;

  params.analysisType.forEach((item, index) => {
    transformedParams.analytics[index] = item.key;
  });
  transformedParams.dates.start = `${params?.date?.from?.year}-${params?.date?.from?.month?.number}-${params?.date?.from?.day}`;

  transformedParams.dates.end = `${params?.date?.to?.year}-${params?.date?.to?.month?.number}-${params?.date?.to?.day}`;

  return transformedParams;
};

export function transformedDetailsHeatMapParams(obj) {
  const params = new URLSearchParams();

  function flattenObject(data, prefix = "") {
    for (const [key, value] of Object.entries(data)) {
      const newPrefix = prefix ? `${prefix}[${key}]` : key;

      if (typeof value === "object" && value !== null) {
        flattenObject(value, newPrefix);
      } else {
        params.set(newPrefix, value);
      }
    }
  }

  flattenObject(obj);
  return params.toString();
}
