import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";

import AnalysisInformation from "~/components/child/AnalysisInformation";
import Accordion from "~/components/child/ui/Accordion";
import TechnicalPointPreview from "~/components/child/PointsPreview";
import Comment from "~/components/child/Comment";
import { RightNav } from "~/components/child/RightNav";
import { AnalysisHistory } from "~/components/child/history/AnalysisHistory";
import { PrivateNote } from "~/components/child/PrivateNote";
import { Loading } from "~/components/child/ui/Loading";
import { NotificationStatus } from "~/components/child/notification/NotificationStatus";
import { TVChartContainer } from "~/components/child/tradingViewMainChart/tvChartContainer/technical/view/TVChartContainer";
import DataFailureAlert from "~/components/child/tradingViewMainChart/ui/DataFailureAlert";
import { chartSize } from "~/components/child/tradingViewMainChart/utils/helpers/tools/createChartStyles";
import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";
import { useMainChartDetails } from "~/utils/hooks";
import { fetchTechnicalDetails } from "./helper";

const TechnicalView = ({ user, permissions }) => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const [details, setDetails] = useState();
  const [activeAnalysis, setActiveAnalysis] = useState(id);

  const [totalValuesOfPrivateNotes, setTotalValuesOfPrivateNotes] = useState(0);

  const { theme: mainChartTheme, isLoading: mainChartIsLoading } =
    useMainChartDetails();

  const entryPriceList = details?.data?.details?.filter(
    (detail) => detail.point_type === "EP"
  );

  const takeProfitList = details?.data?.details?.filter(
    (detail) => detail.point_type === "TP"
  );

  const stopLossList = details?.data?.details?.filter(
    (detail) => detail.point_type === "SL"
  );

  useEffect(() => {
    if (activeAnalysis) {
      fetchTechnicalDetails(activeAnalysis, setDetails, setIsLoading);
    }
  }, [activeAnalysis]);

  const chartProps = useMemo(
    () => ({
      pairDetail: details?.data?.analysis?.pair,
      initChartData: details?.data?.chart_data,
      initInterval: details?.data?.analysis?.time_frame?.time_frame,
      ep: entryPriceList,
      tp: takeProfitList,
      sl: stopLossList,
      createdAt: details?.data?.analysis?.created_at,
      baseDateType: "view",
      theme: mainChartTheme,
    }),
    [details, mainChartTheme]
  );

  return (
    <>
      {!isLoading && details?.data ? (
        <div className="main__router__container">
          <div className="main__router__container__view-box-container scrollbar-style scrollbar-style-thumb">
            <div className="technical-view ">
              <AnalysisInformation
                id={id}
                user={
                  details?.data?.analysis?.user?.first_name +
                  " " +
                  details?.data?.analysis?.user?.last_name
                }
                pairs={details?.data.analysis.pair}
                timeFrame={details?.data.analysis?.time_frame?.time_frame}
                type="Technical"
                technicalType={
                  details?.data?.technical_type &&
                  details?.data?.technical_type[0]?.technical_type
                }
                positionType={changeWordFormatToTitleCase(
                  details?.data.analysis.position_type
                )}
                description={details?.data.analysis.description}
                createDateTime={{
                  time: details?.data?.analysis?.created_at?.split(" ")[1],
                  date: details?.data?.analysis?.created_at?.split(" ")[0],
                }}
                isEditable={
                  id === details.data.analysis.uuid &&
                  user?.uuid === details?.data?.analysis?.user?.uuid
                }
                status={
                  details?.data?.technical_type[0]?.technical_type ===
                    "SETUP" &&
                  (details?.data?.status || "No Touch")
                }
                notifications={
                  <NotificationStatus
                    users={details?.data?.notifications[0]?.receivers?.map(
                      (item) => ({
                        value: item?.first_name + item.last_name,
                      })
                    )}
                  />
                }
              />

              <div className={`${chartSize?.mode2}`}>
                {!mainChartIsLoading && mainChartTheme ? (
                  <TVChartContainer {...chartProps} />
                ) : !mainChartIsLoading ? (
                  <DataFailureAlert />
                ) : (
                  <Loading />
                )}
              </div>
              {(entryPriceList?.length > 0 ||
                takeProfitList?.length > 0 ||
                stopLossList?.length > 0) && (
                <div className="technical-view__entries">
                  {entryPriceList?.length > 0 && (
                    <Accordion
                      className={{
                        root: "technical-view__entries__accordion__ep",
                      }}
                      label={{
                        leftPart: (
                          <span className="technical-view__entries__accordion__ep__label">
                            EP
                            <span className="technical-view__entries__accordion__ep__label-count">
                              ({entryPriceList?.length})
                            </span>
                          </span>
                        ),
                      }}
                      openDefault={true}
                    >
                      {entryPriceList?.map((epItem, index) => {
                        return (
                          <TechnicalPointPreview
                            label={`EP ${index + 1}`}
                            key={index}
                            value={`${epItem.base_amount}${
                              epItem.max_amount !== 0
                                ? `-${epItem.max_amount}`
                                : ""
                            }`}
                            note={epItem.point_description}
                          />
                        );
                      })}
                    </Accordion>
                  )}
                  {takeProfitList?.length > 0 && (
                    <Accordion
                      className={{
                        root: "technical-view__entries__accordion__tp",
                      }}
                      label={{
                        leftPart: (
                          <span className="technical-view__entries__accordion__tp__label">
                            TP
                            <span className="technical-view__entries__accordion__tp__label-count">
                              ({takeProfitList?.length})
                            </span>
                          </span>
                        ),
                      }}
                      openDefault={true}
                    >
                      {takeProfitList?.map((tpItem, index) => {
                        return (
                          <TechnicalPointPreview
                            label={`TP ${index + 1}`}
                            key={index}
                            value={`${tpItem.base_amount}${
                              tpItem.max_amount !== 0
                                ? `-${tpItem.max_amount}`
                                : ""
                            }`}
                            note={tpItem.point_description}
                            riskToReward={tpItem.rr}
                          />
                        );
                      })}
                    </Accordion>
                  )}

                  {stopLossList?.length > 0 && (
                    <div className="technical-view__entries__sl">
                      {stopLossList?.map((slItem, index) => {
                        return (
                          <TechnicalPointPreview
                            label={`SL ${index + 1}`}
                            key={index}
                            value={`${slItem.base_amount}${
                              slItem.max_amount !== 0
                                ? `-${slItem.max_amount}`
                                : ""
                            }`}
                            note={slItem.point_description}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
            {(permissions?.new_comment || permissions?.comment_list) && (
              <Comment permissions={permissions} section="analytics" id={id} />
            )}
          </div>
          <RightNav>
            <Accordion
              className={{ root: "px-0 border-b border-storm-30" }}
              label={{
                leftPart: <span>History</span>,
              }}
              openDefault={true}
            >
              <AnalysisHistory
                setActiveAnalysis={setActiveAnalysis}
                activeAnalysis={activeAnalysis}
                id={id}
              />
            </Accordion>
            <Accordion
              className={{ root: "px-0" }}
              label={{
                leftPart: (
                  <span>
                    Private Note
                    {totalValuesOfPrivateNotes > 0 && (
                      <span className="total">
                        ({totalValuesOfPrivateNotes})
                      </span>
                    )}
                  </span>
                ),
              }}
              openDefault={true}
            >
              {(permissions?.new_private_note ||
                permissions?.private_note_list) && (
                <PrivateNote
                  permissions={permissions}
                  section="analytics"
                  setTotalCount={(value) => setTotalValuesOfPrivateNotes(value)}
                  id={id}
                />
              )}
            </Accordion>
          </RightNav>
        </div>
      ) : !isLoading ? (
        <div className="main__router__container">
          <div className="empty-analysis">
            <h4 className="empty-analysis__title">
              There isn't any technical analysis with this Id!
            </h4>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default TechnicalView;
