import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "~/components/child/ui/Button";
import { Loading } from "~/components/child/ui/Loading";
import Breadcrumb from "~/components/child/Breadcrumb";
import TechnicalTVChartContainer from "~/components/child/tradingViewMainChart/tvChartContainer/technical/create/TVChartContainer";
import FundamentalTVChartContainer from "~/components/child/tradingViewMainChart/tvChartContainer/fundamental/create/TVChartContainer";
import { useSanitizedMainChartData } from "~/components/child/tradingViewMainChart/utils/hooks/useSanitizedMainChartData";
import { chartSize } from "~/components/child/tradingViewMainChart/utils/helpers/tools/createChartStyles";
import DataFailureAlert from "~/components/child/tradingViewMainChart/ui/DataFailureAlert";
import { NotificationModal } from "~/components/child/notification/ReceiveNotification";
import Modal from "~/components/child/ui/Modal";
import NewAnalysis from "./NewAnalysis";
import TechnicalDetails from "./technical/details/Details";
import FundamentalDetails from "./fundamental/Details";
import TechnicalPreview from "./technical/Preview";
import FundamentalPreview from "./fundamental/Preview";
import OnChainCreation from "./onChain";

import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";
import { onChangeCheckboxes } from "~/utils/onChangeCheckboxes";
import { useMainChartDetails } from "~/utils/hooks";
import { createOrEditAnalysis } from "./helper";
import { fetchTechnicalDetails } from "../helper";

import analysisImg from "~/assets/img/analysisImg.svg";

const CreateNew = ({ permissions, user }) => {
  const initialAnalysisValues = {
    new: {
      pair: {
        value: "BTCUSDT",
        key: "BTCUSDT",
        image: [
          "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
          "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
        ],
        baseSymbol: "BTC",
        quoteSymbol: "USDT",
        exchange: "binance",
      },
      analysisType: "technical",
    },
    technical: {
      type: "setup",
      timeFrame: {
        key: 4,
        value: "15 Minutes",
      },
      positionType: "long",
      points: {
        ep: [{ value: "", desc: "" }],
        tp: [{ value: "", desc: "" }],
        sl: [{ value: "", desc: "" }],
      },
      notificationUsers: [],
      description: "",
    },
    fundamental: {
      entries: [],
      images: [],
      description: "",
    },
    onChain: { title: "", description: "", images: [] },
  };
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [step, setStep] = useState("newAnalysis");
  const [analysisDetails, setAnalysisDetails] = useState();
  const [analysisValues, setAnalysisValues] = useState({
    ...initialAnalysisValues,
  });
  const [techChartData, setTechChartData] = useState(null);
  const [fundChartData, setFundChartData] = useState(null);
  const [notification, setNotification] = useState({
    modal: false,
    switch: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [dataHasChanged, setDataHasChanged] = useState(false);

  const {
    chartData,
    theme: mainChartTheme,
    isLoading: mainChartIsLoading,
  } = useMainChartDetails();
  const sanitizedMainChartData = useSanitizedMainChartData(chartData);

  const isEditMode = step === "details" && id ? true : false;
  const allPointsHaveValues = Object.values(
    analysisValues.technical.points
  ).every((arr) => {
    return arr.some((item) => item.value.trim() !== "");
  });
  const hasValue = Object.values(analysisValues.technical.points).some(
    (pointGroup) => {
      return pointGroup.some((point) => point.value !== "");
    }
  );
  useEffect(() => {
    // just once
    if (sanitizedMainChartData) {
      if (!techChartData) setTechChartData(sanitizedMainChartData);
      if (!fundChartData) setFundChartData(sanitizedMainChartData);
    }
  }, [sanitizedMainChartData, fundChartData, techChartData]);

  useEffect(() => {
    setErrorMessage(undefined);
  }, [analysisValues]);

  useEffect(() => {
    if (id) {
      fetchTechnicalDetails(id, setAnalysisDetails, setIsLoading);
      setStep("details");
    } else {
      setStep("newAnalysis");
      setAnalysisValues({ ...initialAnalysisValues });
    }
  }, [id]);

  useEffect(() => {
    const getPoints = (title) => {
      return analysisDetails?.data?.details
        ?.filter((detail) => detail.point_type === title)
        .map((item) => ({
          value: `${item.base_amount}${
            item.max_amount > 0 ? "-" + item.max_amount : ""
          }`,
          desc: item.point_description,
          rr: item.rr,
        }));
    };
    if (analysisDetails) {
      setAnalysisValues({
        ...analysisValues,
        new: {
          ...analysisValues.new,
          pair: {
            value: analysisDetails?.data?.analysis?.pair?.pair,
            key: analysisDetails?.data?.analysis.pair.key,
            image: analysisDetails?.data?.analysis.pair.image,
            baseSymbol: analysisDetails?.data?.analysis.pair.base_symbol,
            quoteSymbol: analysisDetails?.data?.analysis.pair.quote_symbol,
            exchange: analysisDetails?.data?.analysis.pair.exchange,
          },
        },
        technical: {
          ...analysisValues.technical,
          type: analysisDetails?.data?.technical_type
            ? analysisDetails?.data?.technical_type[0]?.technical_type
            : "setup",
          timeFrame: {
            key: analysisDetails?.data?.analysis?.time_frame?.id,
            value: analysisDetails?.data?.analysis?.time_frame?.time_frame,
          },
          positionType:
            analysisDetails?.data?.analysis?.position_type?.toLowerCase(),
          points: {
            ep:
              getPoints("EP")?.length > 0
                ? getPoints("EP")
                : [{ value: "", desc: "" }],
            tp:
              getPoints("TP")?.length > 0
                ? getPoints("TP")
                : [{ value: "", desc: "", rr: "" }],
            sl:
              getPoints("SL")?.length > 0
                ? getPoints("SL")
                : [{ value: "", desc: "" }],
          },
          description: analysisDetails?.data?.analysis.description || "",
        },
      });
      setTechChartData(
        JSON.parse(JSON.parse(analysisDetails?.data?.chart_data))
      );
    }
  }, [analysisDetails]);

  if (step !== "onChainCreation")
    return (
      <div className="main__router__container">
        <div className="main__router__container__aside-left-box scrollbar-style-thumb scrollbar-style">
          <aside
            className={`flex flex-col m-4 ${
              step === "newAnalysis" ? "mt-24" : ""
            }`}
          >
            {permissions?.new_analytic ? (
              <>
                {step === "newAnalysis" ? (
                  <NewAnalysis
                    isEditMode={isEditMode}
                    analysisValues={analysisValues}
                    setAnalysisValues={setAnalysisValues}
                    setTechChartData={setTechChartData}
                    setFundChartData={setFundChartData}
                  />
                ) : (
                  <>
                    {!isEditMode ? (
                      <Breadcrumb
                        steps={[
                          { label: "Analysis type", active: 0 },
                          {
                            label: "Details",
                            active: step === "details" ? 1 : 0,
                          },
                          {
                            label: "Preview",
                            active: step === "preview" ? 1 : 0,
                          },
                        ]}
                      />
                    ) : (
                      <h4 className="mt-8 mb-4 text-base text-sky-70">
                        Edit{" "}
                        {changeWordFormatToTitleCase(
                          analysisValues?.new?.analysisType
                        )}{" "}
                        {changeWordFormatToTitleCase(
                          analysisValues?.technical.type
                        )}
                      </h4>
                    )}

                    {step === "details" && (
                      <>
                        {analysisValues?.new?.analysisType === "technical" ? (
                          <>
                            <TechnicalDetails
                              analysisValues={analysisValues}
                              setAnalysisValues={setAnalysisValues}
                              isEditMode={isEditMode}
                              setDataHasChanged={(value) =>
                                setDataHasChanged(value)
                              }
                              notification={notification}
                              setNotification={setNotification}
                            />
                          </>
                        ) : (
                          analysisValues?.new?.analysisType ===
                            "fundamental" && (
                            <FundamentalDetails
                              analysisValues={analysisValues}
                              setAnalysisValues={setAnalysisValues}
                            />
                          )
                        )}
                      </>
                    )}
                    {step === "preview" && (
                      <>
                        {analysisValues?.new?.analysisType === "technical" ? (
                          <TechnicalPreview analysisValues={analysisValues} />
                        ) : (
                          analysisValues?.new?.analysisType ===
                            "fundamental" && (
                            <FundamentalPreview
                              analysisValues={analysisValues}
                            />
                          )
                        )}
                      </>
                    )}
                  </>
                )}

                <div className="flex my-16 justify-end gap-4">
                  {step !== "newAnalysis" ? (
                    <Button
                      variant="outline"
                      className="w-1/2"
                      onClick={() => {
                        if (isEditMode)
                          navigate(
                            `/${analysisValues?.new?.analysisType}-view/${id}`
                          );
                        else if (step === "details") setStep("newAnalysis");
                        else setStep("details");
                      }}
                    >
                      {isEditMode ? "Cancel" : "Back"}
                    </Button>
                  ) : null}

                  <Button
                    variant="primary"
                    isLoading={isLoading}
                    className="w-1/2"
                    disabled={
                      (step === "details" &&
                        ((analysisValues?.new?.analysisType === "technical"
                          ? analysisValues.technical.type === "setup"
                            ? !allPointsHaveValues
                            : !hasValue &&
                              analysisValues.technical.description?.length === 0
                          : analysisValues.fundamental.entries?.length === 0 ||
                            analysisValues?.images?.length > 5) ||
                          (!dataHasChanged && isEditMode))) ||
                      isLoading
                    }
                    onClick={() => {
                      if (step === "newAnalysis") {
                        if (analysisValues?.new?.analysisType === "on-chain")
                          setStep("onChainCreation");
                        else setStep("details");
                      } else if (isEditMode)
                        createOrEditAnalysis(
                          id,
                          analysisValues?.new?.analysisType,
                          setIsLoading,
                          analysisValues,
                          analysisValues?.new?.analysisType === "fundamental"
                            ? fundChartData
                            : techChartData,
                          (uuid) => {
                            navigate(
                              `/${analysisValues?.new?.analysisType}-view/${uuid}`
                            );
                          },
                          setErrorMessage
                        );
                      else if (step === "details") setStep("preview");
                      else if (step === "preview")
                        createOrEditAnalysis(
                          id,
                          analysisValues?.new?.analysisType,
                          setIsLoading,
                          analysisValues,
                          analysisValues?.new?.analysisType === "fundamental"
                            ? fundChartData
                            : techChartData,
                          () => {
                            navigate("/analysisList");
                          },
                          setErrorMessage,
                          notification.switch,
                          user.uuid
                        );
                    }}
                  >
                    {isEditMode
                      ? "Save Changes"
                      : step === "preview"
                      ? id
                        ? "Edit"
                        : "Apply"
                      : "Next"}
                  </Button>
                </div>
                {errorMessage && Object.keys(errorMessage) && (
                  <div className="flex flex-col items-center mb-8 mt-2">
                    {Object.keys(errorMessage)?.map((key) => (
                      <p
                        key={key}
                        className="mt-1 font-roboto text-sm text-infrared-key text-center overflow-auto"
                      >
                        {errorMessage[key]}
                      </p>
                    ))}
                  </div>
                )}
              </>
            ) : (
              permissions !== undefined && (
                <p className="my-[50%] mx-auto text-infrared-key">
                  You don't have permission to add new analysis.
                </p>
              )
            )}
          </aside>
        </div>
        <>
          {step !== "newAnalysis" ? (
            <div className={`${chartSize?.mode1}`}>
              {mainChartTheme && !mainChartIsLoading ? (
                <>
                  {analysisValues?.new?.analysisType === "technical" &&
                  techChartData ? (
                    <TechnicalTVChartContainer
                      ep={analysisValues.technical.points.ep}
                      sl={analysisValues.technical.points.sl}
                      tp={analysisValues.technical.points.tp}
                      pairDetail={analysisValues.new.pair}
                      initInterval={analysisValues.technical.timeFrame.value}
                      baseDateType={"create"}
                      step={step}
                      theme={mainChartTheme}
                      techChartData={techChartData}
                      setTechChartData={setTechChartData}
                      setEditDataHasChanged={setDataHasChanged}
                    />
                  ) : (
                    analysisValues?.new?.analysisType === "fundamental" &&
                    fundChartData && (
                      <FundamentalTVChartContainer
                        pairDetail={analysisValues.new.pair}
                        interval={"D"}
                        entries={analysisValues.fundamental?.entries}
                        step={step}
                        theme={mainChartTheme}
                        fundChartData={fundChartData}
                        setFundChartData={setFundChartData}
                      />
                    )
                  )}
                </>
              ) : !mainChartIsLoading && !mainChartTheme ? (
                <DataFailureAlert />
              ) : (
                <Loading />
              )}
            </div>
          ) : (
            <div className="flex flex-row items-center justify-center w-full">
              <img className=" w-4/5" src={analysisImg} alt="" />
            </div>
          )}
        </>
        {notification.modal && (
          <Modal className="w-2/5 min-h-[35%]">
            <NotificationModal
              selectedUsers={[...analysisValues?.technical?.notificationUsers]}
              setShowNotificationModal={(value) =>
                setNotification({ ...notification, modal: value })
              }
              onSelectUser={(item) =>
                setAnalysisValues({
                  ...analysisValues,
                  technical: {
                    ...analysisValues.technical,
                    notificationUsers: onChangeCheckboxes(
                      analysisValues.technical.notificationUsers,
                      {
                        key: item.key,
                        value: item.value,
                      }
                    ),
                  },
                })
              }
            />
          </Modal>
        )}
      </div>
    );
  else
    return (
      <OnChainCreation
        analysisValues={analysisValues}
        setAnalysisValues={setAnalysisValues}
        onBack={() => setStep("newAnalysis")}
        onSubmit={() =>
          createOrEditAnalysis(
            undefined,
            analysisValues?.new?.analysisType,
            setIsLoading,
            analysisValues,
            null,
            () => {
              navigate("/analysisList");
            },
            setErrorMessage
          )
        }
        isLoading={isLoading}
        errorMessage={errorMessage}
      />
    );
};

export default CreateNew;
