export const calculateRangeAverage = (value) => {
  if (value.includes("-")) {
    // If the value is a range of numbers
    var range = value.split("-");
    var start = Number(range[0]);
    var end = Number(range[1]);

    if (isNaN(start) || isNaN(end)) {
      // Invalid range, return 0 as the average
      return 0;
    }

    return (start + end) / 2;
  } else {
    // Invalid value, return 0 as the average
    return value;
  }
};
export const calculateTotalAverage = (array) => {
  var sum = 0;
  var count = 0;
  for (var i = 0; i < array.length; i++) {
    var value = array[i].value;

    if (value?.includes("-")) {
      var rangeAverage = calculateRangeAverage(value);

      sum += rangeAverage;
      count++;
    } else {
      if (!isNaN(value)) {
        sum += Number(value);
        count++;
      }
    }
  }

  var average = count > 0 ? sum / count : 0;
  return Number(average);
};
