export const riskToRewardResult = (ep, sl, tp) => {
  if (Number(ep) !== 0 && Number(sl) !== 0 && Number(tp) !== 0) {
    const result = Math.abs(
      (Number(tp) - Number(ep)) / (Number(ep) - Number(sl))
    ).toFixed(2);
    if (!isNaN(result) && isFinite(result) && result !== 0 && result < 99.99)
      return result;
  }
  return null;
};
