import React, { useEffect, useRef } from "react";

import { widget } from "~/charting_library";
import { WidgetCreator } from "../../../utils/classes/widgetCreator";
import { convertDateToUnixTime } from "../../../utils/converters/convertDateToUnixTime";
import { createPairDataConfig } from "../../../utils/creators/createPairDataConfig";
import { setIntervalChartSource } from "../../../utils/chartSourceModifiers/setIntervalChartSource";
import { createChartConfig } from "../../../config/createChartConfig";

import "../../index.css";

export const TVChartContainer = ({
  pairDetail,
  interval,
  fundChartData,
  setFundChartData,
  entries,
  step,
  theme,
}) => {
  const chartContainerRef = useRef();
  const { baseChartConfig, specificConfigs } = createChartConfig("fundamental");
  const { baseSymbol, quoteSymbol, exchange } = pairDetail;
  const { pair, dataFeed: datafeedUrl } = createPairDataConfig(
    baseSymbol,
    quoteSymbol,
    exchange
  );

  function convertDateToUnix(entry) {
    const dateArray = entry?.date.split("/");
    const date = `${dateArray[2]}-${dateArray[0]}-${
      dateArray[1] < 10 ? "0" + dateArray[1] : dateArray[1]
    }`;

    const resulte = convertDateToUnixTime(1, date);
    return resulte;
  }

  const chartData = setIntervalChartSource(fundChartData, "D");

  useEffect(() => {
    const widgetOptions = {
      ...baseChartConfig?.defaultProps,
      container: chartContainerRef.current,
      datafeed: baseChartConfig?.createDatafeed(datafeedUrl),
      disabled_features: specificConfigs?.create?.disabledFeatures,
      enabled_features: specificConfigs?.create?.enabledFeatures,
      theme: theme,
      symbol: pair,
      interval: interval,
      saved_data: chartData,
    };

    const tvWidget = new widget(widgetOptions);
    const createWidget = new WidgetCreator(tvWidget);

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        if (entries && entries.length > 0) {
          for (const entry of entries) {
            const date = convertDateToUnix(entry);
            createWidget.verticalLine(date, "fundamentalDatePoint");
          }
        }

        tvWidget.save((data) => {
          setFundChartData(data);
        });
        tvWidget.subscribe("undo_redo_state_changed", () => {
          tvWidget.save((data) => {
            setFundChartData(data);
          });
        });
      });
    });

    return () => {
      tvWidget.remove();
    };
  }, [step, theme]);

  return <div ref={chartContainerRef} className={"TVChartContainer"} />;
};

export default TVChartContainer;
