import { callRestGet } from "../../../utils/callRestApi";
import { createFilterQueryParams } from "../../../utils/createFilterQueryParams";

export const listApi = async (page, data) => {
  const result = { data: null, error: null };

  await callRestGet(
    `/dashboard/analytics/filter?page=${page}&${createFilterQueryParams(data)}`,
    null
  )
    .then((res) => {
      if (res.status === 200) {
        result.data = res.data;
      } else {
        result.error = res;
      }
    })
    .catch((err) => {
      if (err.message === "Request failed with status code 401") {
        localStorage.setItem("accessToken", "");
        window.location.href = "/login";
      }
      result.error = err;
    });

  return result;
};
