import React, { useEffect, useRef, useState } from "react";

import { widget } from "~/charting_library";
import { WidgetCreator } from "../../utils/classes/widgetCreator";
import { convertIntervalToTradingViewFormat } from "../../utils/converters/convertTimeFrameToTradingViewFormat";
import { createTradeBoundariesWidgetTimeRange } from "../../utils/creators/createTradeBoundariesWidgetTimeRange";
import { createChartTimeFrame } from "../../utils/creators/createChartTimeFrame";
import { createPairDataConfig } from "../../utils/creators/createPairDataConfig";
import { plotVerticalLineAtCreationTime } from "../../utils/helpers/plotting/plotVerticalLineAtCreationTime";
import { createChartConfig } from "../../config/createChartConfig";
import { timezoneListCustom } from "../../utils/helpers/tools/timezoneListCustom";
import { createWidgetLabelOverrides } from "../../utils/creators/createWidgetLabelOverrides";
import { tvColors } from "../../utils/helpers/tools/tvColors";

import "../index.css";

export const TVChartContainer = React.memo(
  ({
    entryPriceList,
    takeProfitList,
    stopLossList,
    pairDetail,
    initInterval,
    createdAt,
    theme,
    baseDateType,
  }) => {
    const chartContainerRef = useRef();
    const { baseChartConfig, specificConfigs } = createChartConfig("setup");
    const [interval, setInterval] = useState(
      convertIntervalToTradingViewFormat(initInterval)
    );

    const { timeFrom: timeFrameLoadChartFrom, timeTo: timeFrameLoadChartTo } =
      createChartTimeFrame(interval, createdAt.split(" ")[0]);

    const {
      base_symbol: baseSymbol,
      quote_symbol: quoteSymbol,
      exchange,
    } = pairDetail;
    const { pair, dataFeed: datafeedUrl } = createPairDataConfig(
      baseSymbol,
      quoteSymbol,
      exchange
    );

    useEffect(() => {
      const widgetOptions = {
        ...baseChartConfig?.defaultProps,
        container: chartContainerRef.current,
        datafeed: baseChartConfig?.createDatafeed(datafeedUrl),
        disabled_features: specificConfigs?.disabledFeatures,
        enabled_features: specificConfigs?.enabledFeatures,
        theme: theme,
        symbol: pair,
        interval: interval,
        timeframe: { from: timeFrameLoadChartFrom, to: timeFrameLoadChartTo },
        timezone: timezoneListCustom[1],
      };

      const tvWidget = new widget(widgetOptions);
      const createWidget = new WidgetCreator(tvWidget);

      tvWidget.onChartReady(() => {
        tvWidget.headerReady().then(() => {
          if (entryPriceList && entryPriceList.length > 0) {
            for (const ep of entryPriceList) {
              createWidget.trendLine(
                ep?.amount.toString(),
                createTradeBoundariesWidgetTimeRange(
                  interval,
                  createdAt,
                  baseDateType
                ),
                "ep",

                ep.weight &&
                  createWidgetLabelOverrides("trendLineSetup", {
                    text: `${ep.weight}%`,
                    textcolor: tvColors?.blue,
                  })
              );
            }
          }

          if (takeProfitList && takeProfitList.length > 0) {
            for (const tp of takeProfitList) {
              createWidget.trendLine(
                tp?.amount.toString(),
                createTradeBoundariesWidgetTimeRange(
                  interval,
                  createdAt,
                  baseDateType
                ),
                "tp",

                tp.weight &&
                  createWidgetLabelOverrides("trendLineSetup", {
                    text: `${tp.weight}%`,
                    textcolor: tvColors?.green,
                  })
              );
            }
          }

          if (stopLossList && stopLossList.length > 0) {
            for (const sl of stopLossList) {
              createWidget.trendLine(
                sl?.amount.toString(),
                createTradeBoundariesWidgetTimeRange(
                  interval,
                  createdAt,
                  baseDateType
                ),
                "sl",

                sl.weight &&
                  createWidgetLabelOverrides("trendLineSetup", {
                    text: `${sl.weight}%`,
                    textcolor: tvColors?.red,
                  })
              );
            }
          }
          // show created at by vertical_line => style :dashed
          plotVerticalLineAtCreationTime(createdAt, createWidget);

          tvWidget
            .activeChart()
            .onIntervalChanged()
            .subscribe(null, (interval) => {
              setInterval(convertIntervalToTradingViewFormat(interval));
            });
        });
      });

      return () => {
        tvWidget.remove();
      };
    }, [
      entryPriceList,
      takeProfitList,
      stopLossList,
      pairDetail,
      interval,
      createdAt,
      theme,
      baseDateType,
    ]);

    return <div ref={chartContainerRef} className={"TVChartContainer"} />;
  }
);
