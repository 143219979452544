import React, { useState, useEffect } from "react";
import Pagination from "rc-pagination/lib/Pagination";
import { NavLink } from "react-router-dom";

import { heatMapApi } from "~/api/dashboard/heatMapApi";

import ArrowLeftSvg from "~/assets/icon/svgJsx/ArrowLeftSvg";
import ArrowRightSvg from "~/assets/icon/svgJsx/ArrowRightSvg";
import PlusSvg from "~/assets/icon/svgJsx/PlusSvg";
import ArrowLineRightSvg from "~/assets/icon/svgJsx/ArrowLineRightSvg";
import filterSvg from "~/assets/icon/filter.svg";
import TrashSvg from "~/assets/icon/svgJsx/TrashSvg";

import { transformedFilterItems } from "~/utils/getTransformedData";

import { Filter } from "~/components/child/Filter";
import { Loading } from "~/components/child/ui/Loading";
import Modal from "~/components/child/ui/Modal";
import NewHeatMap from "./createNew";

const HeatMapList = ({ permissions }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [listItems, setListItems] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const [confirmDeletion, setConfirmDeletion] = useState(undefined);
  const [filterItemsCount, setFilterItemsCount] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState({
    pairs: [],
    date: [],
    analyzers: [],
    type: [],
    timeFrame: [],
  });
  const fetchHeatMapList = async (page) => {
    setIsLoading(true);
    try {
      const result = await heatMapApi.list(
        page,
        transformedFilterItems(appliedFilters)
      );
      setListItems(result.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching pairs data:", error);
    }
  };
  const [errorMessage, setErrorMessage] = useState("");

  const deleteHeatmap = async (id) => {
    setIsLoading(true);

    heatMapApi.delete(id).then((res) => {
      if (res && !res.error) {
        setConfirmDeletion(undefined);
        setIsLoading(false);
        fetchHeatMapList();
      } else if (res?.error) {
        setIsLoading(false);
        setErrorMessage(res?.error.response?.data.message);
      }
    });
  };

  useEffect(() => {
    if (currentPage && !popUpIsOpen && permissions?.heatmap_list) {
      fetchHeatMapList(currentPage);
    }
  }, [popUpIsOpen, permissions]);

  useEffect(() => {
    if (!showFilter) {
      setFilterItemsCount(0);
      for (const key in appliedFilters) {
        if (Array.isArray(appliedFilters[key])) {
          setFilterItemsCount((prevFilterItemsCount) => {
            if (key === "date" && appliedFilters[key].length > 0)
              return (prevFilterItemsCount += 1);
            return (prevFilterItemsCount += appliedFilters[key].length);
          });
        }
      }
    }
  }, [appliedFilters, showFilter]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchHeatMapList(page);
  };

  return (
    <div className="main__router__container">
      {showFilter && (
        <Filter
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          isOpen={showFilter}
          setIsOpen={setShowFilter}
          heatMapListFilter={true}
          onSubmitFilters={() => {
            setCurrentPage(1);
            fetchHeatMapList(1);
          }}
          totalResults={listItems?.meta?.total}
          fullAccess={permissions.full_access}
        />
      )}

      {popUpIsOpen && <NewHeatMap setPopUpIsOpen={setPopUpIsOpen} />}

      <div className="main__router__container__list-box-container heatMapList">
        <div className="heatMapList__buttons">
          {permissions?.heatmap_filter && (
            <button
              className="heatMapList__buttons__filter filterBtn"
              onClick={() => setShowFilter(true)}
            >
              <img src={filterSvg} alt="filter icon" />
              Filter
              {filterItemsCount > 0 && <span>{filterItemsCount} Items</span>}
              <ArrowLineRightSvg />
            </button>
          )}
          {permissions?.new_heatmap && (
            <button
              onClick={() => setPopUpIsOpen(true)}
              className="heatMapList__buttons__addNew"
            >
              <PlusSvg />
              <span>create New HeatMap</span>
            </button>
          )}
        </div>
        {!isLoading && listItems?.data?.length > 0 ? (
          <div>
            <div className="list-table-cstm-one scrollbar-style scrollbar-style-thumb">
              <table className="list-table-cstm-one__content-table">
                <thead>
                  <tr>
                    <th>Row</th>
                    <th>Name</th>
                    <th>User</th>
                    <th>Pair</th>
                    <th>Timeframe</th>
                    <th>Date</th>
                    <th>Detail</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {listItems?.data?.map((item, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * 10 + index + 1}</td>
                      <td className="list-table-cstm-one__content-table__title">
                        {item.title}
                      </td>

                      <td className="list-table-cstm-one__content-table__flex">
                        <div className="list-table-cstm-one__content-table__user">
                          {item.user_name}
                        </div>
                      </td>

                      <td> {item?.pair?.pair}</td>
                      <td>{item.timeframe?.time_frame || "-"}</td>

                      <td className="list-table-cstm-one__content-table__date-time">
                        <div className="list-table-cstm-one__content-table__date-time__date">
                          {item.date?.split(" ")[0]}
                        </div>
                        <div className="list-table-cstm-one__content-table__date-time__time">
                          {item.date?.split(" ")[1]}
                        </div>
                      </td>

                      <td className="list-table-cstm-one__content-table__details-arrow">
                        <NavLink
                          className="flex justify-center items-center"
                          to={`/heatMap/${item?.uuid}`}
                        >
                          <ArrowRightSvg />
                        </NavLink>
                      </td>

                      <td className="list-table-cstm-one__content-table__flex">
                        <button onClick={() => setConfirmDeletion(item?.uuid)}>
                          <TrashSvg />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="pagination-one">
              <Pagination
                current={currentPage}
                total={listItems?.meta?.total}
                pageSize={10}
                onChange={handlePageChange}
                showSizeChanger={false}
                prevIcon={<ArrowLeftSvg />}
                nextIcon={<ArrowRightSvg />}
              />
            </div>
          </div>
        ) : !isLoading ? (
          <div className="empty-heatMap">
            <h4 className="empty-heatMap__title">No Result!</h4>
          </div>
        ) : (
          <Loading size="large" />
        )}
      </div>
      {confirmDeletion && (
        <Modal className="px-6">
          <p className="text-soil-dark mb-2 flex flex-col items-baseline gap-2 font-roboto text-base font-normal leading-snug m-0">
            You are deleting "
            {
              listItems?.data.find((item) => item.uuid === confirmDeletion)
                .title
            }
            " heatmap.
            <span> Are you sure?</span>
          </p>
          <div className="flex items-center flex-row-reverse gap-4 justify-between">
            <button
              disabled={isLoading || errorMessage.length > 0}
              className="btn-cstm btn-dark-cstm"
              onClick={() => deleteHeatmap(confirmDeletion)}
              type="submit"
            >
              Yes, I'm sure.
            </button>

            <button
              onClick={() => {
                setErrorMessage("");
                setConfirmDeletion(undefined);
              }}
              className="btn-cstm btn-outline-dark-cstm"
            >
              cancel
            </button>
          </div>
          {errorMessage && (
            <p className="profile__confirm__alert__error">{errorMessage}</p>
          )}
        </Modal>
      )}
    </div>
  );
};

export default HeatMapList;
