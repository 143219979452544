import React from "react";
import { useTimeFramesList, useUsersList } from "~/utils/hooks";
import { onChangeCheckboxes } from "~/utils/onChangeCheckboxes";
import DatePicker from "./ui/datePicker/DatePicker";
import Accordion from "./ui/Accordion";
import Dropdown from "~/components/child/ui/DropDown";
import Symbols from "~/components/child/Symbols";
import ArrowLineLeftSvg from "~/assets/icon/svgJsx/ArrowLineLeftSvg";
import filterSvg from "~/assets/icon/filter.svg";
import removeSvg from "~/assets/icon/remove.svg";
import searchSvg from "~/assets/icon/search.svg";

export const Filter = ({
  appliedFilters,
  setAppliedFilters,
  onSubmitFilters,
  isOpen,
  setIsOpen,
  heatMapListFilter = false,
  fullAccess = false,
}) => {
  const timeFramesList = useTimeFramesList()?.data;
  const analyzersList = useUsersList(true)?.data;

  const handleFilterSubmit = (event) => {
    event.preventDefault();
    onSubmitFilters();
    setIsOpen(false);
  };

  const handleRemoveItem = (property, item) => {
    setAppliedFilters((prevFilters) => {
      const updatedItems = prevFilters[property].filter(
        (value) => value !== item
      );
      return {
        ...prevFilters,
        [property]: updatedItems,
      };
    });
  };
  const emitDatePicker = (value) => {
    setAppliedFilters({
      ...appliedFilters,
      date: value !== null ? [...value] : [], // Update date property with selected dates
    });
  };
  return (
    <div
      className={`filter scrollbar-style scrollbar-style-thumb ${
        isOpen ? "filter-isOpen" : ""
      }`}
    >
      <div className="filter__header">
        <span className="filter__header__filterTitle">
          <img src={filterSvg} alt="filter icon" />
          Filter
        </span>

        <button onClick={() => setIsOpen(false)}>
          <ArrowLineLeftSvg />
        </button>
      </div>
      <div className="filter__appliedFilters">
        <div className="filter__appliedFilters__header">
          <span className="filter__appliedFilters__header__title">
            Applied filters
          </span>
          <button
            onClick={() =>
              setAppliedFilters({
                pairs: [],
                date: [],
                analyzers: [],
                type: [],
                timeFrame: [],
              })
            }
            className="filter__appliedFilters__header__clear"
          >
            clear all
          </button>
        </div>
        <div className="filter__appliedFilters__items">
          <div className="filter__appliedFilters__items">
            {Object.entries(appliedFilters).map(([key, value]) => {
              if (value?.length > 0)
                if (key === "date") {
                  return (
                    <span key={key}>
                      {`0${value[0]?.month.number}/${value[0]?.day}/${value[0]?.year}`}
                      {value[1] &&
                        `- 0${value[1]?.month.number}/${value[1]?.day}/${value[1]?.year}`}
                      <button
                        onClick={() => {
                          handleRemoveItem(key, value[0]);
                          handleRemoveItem(key, value[1]);
                        }}
                      >
                        <img src={removeSvg} alt="remove icon" />
                      </button>
                    </span>
                  );
                }
              return value.map((item) => {
                if (item?.value)
                  return (
                    <span key={item.value}>
                      {item.value}
                      <button onClick={() => handleRemoveItem(key, item)}>
                        <img src={removeSvg} alt="remove icon" />
                      </button>
                    </span>
                  );
                return null;
              });
            })}
          </div>
        </div>
      </div>

      <form className="filter__form" onSubmit={handleFilterSubmit}>
        <Accordion
          className={{ root: "filter__form__field" }}
          label={{
            leftPart: (
              <span className="filter__form__field-label">
                Pairs
                {appliedFilters?.pairs.length > 0 && (
                  <span className="filter__form__field-totalSelected">
                    ({appliedFilters.pairs.length})
                  </span>
                )}
              </span>
            ),
          }}
          openDefault={true}
        >
          <Symbols
            hasLabel={false}
            multipleValues
            placeholder={
              <div className="flex items-center justify-between py-[0.375rem] pl-2 gap-2">
                <img src={searchSvg} alt="search icon" className="" />
                <span className=" text-sm text-oil-50">search</span>
              </div>
            }
            shouldShowSelectedValues={false}
            value={
              appliedFilters?.pairs?.length > 0
                ? appliedFilters.pairs
                : undefined
            }
            onSelect={(item) => {
              setAppliedFilters((prevFilters) => ({
                ...prevFilters,
                pairs: onChangeCheckboxes(appliedFilters.pairs, {
                  key: item.key,
                  value: item.value,
                }),
              }));
            }}
          />
        </Accordion>

        <Accordion
          className={{ root: "filter__form__field" }}
          label={{
            leftPart: (
              <span className="filter__form__field-label">Creation date</span>
            ),
          }}
          openDefault={true}
        >
          <div className="filter__form__field__datePicker">
            <DatePicker
              date={appliedFilters.date || []}
              emitDatePicker={emitDatePicker}
              range={true}
            />
          </div>
        </Accordion>
        {fullAccess && (
          <Accordion
            className={{ root: "filter__form__field" }}
            label={{
              leftPart: (
                <span className="filter__form__field-label">
                  Analyzers
                  {appliedFilters?.analyzers.length > 0 && (
                    <span className="filter__form__field-totalSelected">
                      ({appliedFilters.analyzers.length})
                    </span>
                  )}
                </span>
              ),
            }}
            openDefault={true}
          >
            <Dropdown
              searchable
              placeholder={
                <div className="flex items-center justify-between py-[0.375rem] pl-2 gap-2">
                  <img src={searchSvg} alt="search icon" className="" />
                  <span className=" text-sm text-oil-50">search</span>
                </div>
              }
              shouldShowSelectedValues={false}
              multipleValues
              value={
                appliedFilters?.analyzers?.length > 0
                  ? appliedFilters?.analyzers
                  : undefined
              }
              optionsList={analyzersList?.map((analyzer) => ({
                key: analyzer.uuid,
                value: analyzer.first_name + " " + analyzer.last_name,
              }))}
              renderItem={(item) => (
                <span className="py-3 pl-2 text-xs font-roboto w-full text-left">
                  {item.value}
                </span>
              )}
              onSelect={(item) =>
                setAppliedFilters((prevFilters) => ({
                  ...prevFilters,
                  analyzers: onChangeCheckboxes(appliedFilters.analyzers, {
                    key: item.key,
                    value: item.value,
                  }),
                }))
              }
            />
          </Accordion>
        )}
        {!heatMapListFilter && (
          <Accordion
            className={{ root: "filter__form__field" }}
            label={{
              leftPart: (
                <span className="filter__form__field-label">
                  Type
                  {appliedFilters?.type?.length > 0 && (
                    <span className="filter__form__field-totalSelected">
                      ({appliedFilters?.type?.length})
                    </span>
                  )}
                </span>
              ),
            }}
            openDefault={true}
          >
            <div className="filter__form__field__type">
              {["Fundamental", "Technical", "On_chain"].map((type) => (
                <label
                  key={type}
                  className={`filter__form__field__type-label filter__form__field__type-label${
                    appliedFilters.type.some(
                      (obj) => obj["key"] === type.toLowerCase()
                    )
                      ? "-isActive"
                      : ""
                  }`}
                >
                  <input
                    className="filter__form__field__type-checkbox"
                    type="checkbox"
                    checked={appliedFilters.type.some(
                      (obj) => obj["key"] === type.toLowerCase()
                    )}
                    onChange={() =>
                      setAppliedFilters((prevFilters) => ({
                        ...prevFilters,
                        type: onChangeCheckboxes(appliedFilters.type, {
                          key: type.toLowerCase(),
                          value: type,
                        }),
                      }))
                    }
                  />
                  {type}
                </label>
              ))}
            </div>
          </Accordion>
        )}

        {(heatMapListFilter ||
          appliedFilters.type.some((obj) => obj["key"] === "technical")) && (
          <Accordion
            className={{ root: "filter__form__field" }}
            label={{
              leftPart: (
                <span className="filter__form__field-label">
                  Timeframe
                  {appliedFilters?.timeFrame.length > 0 && (
                    <span className="filter__form__field-totalSelected">
                      ({appliedFilters.timeFrame.length})
                    </span>
                  )}
                </span>
              ),
            }}
            openDefault={true}
          >
            <Dropdown
              searchable
              placeholder={
                <div className="flex items-center justify-between py-[0.375rem] pl-2 gap-2">
                  <img src={searchSvg} alt="search icon" className="" />
                  <span className=" text-sm text-oil-50">search</span>
                </div>
              }
              shouldShowSelectedValues={false}
              multipleValues
              value={
                appliedFilters?.timeFrame?.length > 0
                  ? appliedFilters?.timeFrame
                  : undefined
              }
              optionsList={timeFramesList?.map((timeFrame) => ({
                key: timeFrame.id,
                value: timeFrame.time_frame,
              }))}
              renderItem={(item) => (
                <span className="py-3 pl-2 text-xs font-roboto w-full text-left">
                  {item.value}
                </span>
              )}
              onSelect={(item) =>
                setAppliedFilters((prevFilters) => ({
                  ...prevFilters,
                  timeFrame: onChangeCheckboxes(appliedFilters.timeFrame, {
                    key: item.key,
                    value: item.value,
                  }),
                }))
              }
            />
          </Accordion>
        )}

        <div className="filter__form__buttons">
          <button
            onClick={() => setIsOpen(false)}
            className="btn-cstm btn-outline-dark-cstm"
          >
            Back
          </button>

          <button type="submit" className="btn-cstm btn-dark-cstm">
            Apply
          </button>
        </div>
      </form>
    </div>
  );
};
