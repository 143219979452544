import React from "react";
import { useNavigate } from "react-router-dom";
import { PairsSymbol } from "~/components/child/Symbols";
import Button from "~/components/child/ui/Button";
import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";
import { getTechnicalStatusTheme } from "~/utils/getTechnicalStatusTheme";

const AnalysisInformation = ({
  id,
  user,
  pairs,
  timeFrame,
  type,
  positionType,
  description,
  createDateTime,
  analysisName,
  technicalType,
  isEditable = true,
  vault,
  status,
  notifications,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="view-data-header-cstm">
        {createDateTime && (
          <div className="text-storm-60 font-roboto flex items-center justify-between">
            <span>
              {createDateTime.date} - {createDateTime.time}
            </span>
            <div className="flex items-center">
              {type === "Technical" && notifications && notifications}
              {type === "Technical" && isEditable === true && (
                <Button
                  className="font-robotoMed rounded-[2rem] py-[0.375rem] px-3"
                  variant="primary"
                  onClick={() => navigate(`/?id=${id}`)}
                >
                  Edit Analysis
                </Button>
              )}
            </div>
          </div>
        )}

        <div
          className={`view-data-header-cstm__info ${
            type === "Setup" ? "my-0" : ""
          } `}
        >
          <div className="view-data-header-cstm__info__left">
            <PairsSymbol
              pair={pairs.pair}
              image={pairs.image}
              className="text-storm-80 text-lg font-robotoMed"
            />
            <div className="view-data-header-cstm__info__left__time-frame">
              <span>{timeFrame}</span>
            </div>
            {vault && (
              <div className="text-sky-60 text-base font-bold">{vault}</div>
            )}

            {type === "Technical" && (
              <div className="view-data-header-cstm__info__left__type">
                {type}{" "}
                {technicalType && changeWordFormatToTitleCase(technicalType)}
              </div>
            )}
            {positionType && (
              <div
                className={`view-data-header-cstm__info__left__position-type ${
                  positionType === "Long" ? "long" : "short"
                }`}
              >
                {positionType}
              </div>
            )}
            {status && (
              <span
                className={`border py-[0.3125rem] px-4 text-base rounded-3xl ${getTechnicalStatusTheme(
                  status.toUpperCase()
                )}`}
              >
                {changeWordFormatToTitleCase(status)}
              </span>
            )}

            {type !== "Technical" && (
              <div className="text-sky-70 text-base font-roboto flex items-center gap-1 ml-2">
                <span className="font-robotoMed">By</span>
                {user}
              </div>
            )}
          </div>

          {type === "Technical" && user ? (
            <div className="view-data-header-cstm__info__right">
              <span>{user}</span>
            </div>
          ) : (
            notifications && notifications
          )}
        </div>
        {analysisName && (
          <p className="view-data-header-cstm__analysisName">{analysisName}</p>
        )}
        {description && (
          <div className="view-data-header-cstm__description">
            <p>{description}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default AnalysisInformation;
