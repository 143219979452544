import React from "react";

function AddPhotoSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="25"
      fill="none"
      viewBox="0 0 34 25"
    >
      <path
        fill="#002247"
        d="M30.085 24.995c1.686 0 3.113-1.358 3.113-3.106V3.252c0-1.683-1.427-3.106-3.113-3.106H3.112A3.11 3.11 0 000 3.252v18.637c0 1.748 1.362 3.107 3.112 3.107h26.973zM7.262 3.77c1.945 0 3.631 1.683 3.631 3.624 0 2.007-1.686 3.624-3.631 3.624a3.622 3.622 0 01-3.631-3.624c0-1.941 1.621-3.624 3.631-3.624zM4.15 20.854v-3.106l4.603-4.595c.324-.324.778-.324 1.103 0l3.63 3.56 8.754-8.737c.324-.324.778-.324 1.102 0l5.706 5.63v7.248H4.15z"
        opacity="0.2"
      ></path>
    </svg>
  );
}

export default AddPhotoSvg;
