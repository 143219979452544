// * Description : Get main chart data and remove additional data of useMainChartDataDetails.js.
// * is Like : [shapes, indicator(price || Non-price)]

import { useEffect, useState } from "react";
import {
  validateInput,
  validateInputType,
} from "../helpers/tools/validateInput";

export const useSanitizedMainChartData = (chartData) => {
  const functionName = useSanitizedMainChartData.name;

  validateInput(chartData, "chartData", functionName);
  validateInputType(chartData, "chartData", ["object"], functionName);

  const [sanitizedChartData, setSanitizedChartData] = useState(null);

  useEffect(() => {
    const sanitizeMainChartData = async () => {
      if (chartData) {
        const filteredChartsPaneAndSources = {
          ...chartData,
          charts: chartData?.charts.map((chart) => ({
            ...chart,
            panes: chart.panes
              .map((pane) => ({
                ...pane,
                sources: pane.sources.filter(
                  (source) =>
                    source.id === "_seriesId" || source.type === "study_Volume"
                ),
              }))
              .filter((pane) => pane.mainSourceId === "_seriesId"),
          })),
        };
        setSanitizedChartData(filteredChartsPaneAndSources);
      }
    };

    sanitizeMainChartData();
  }, [chartData]);

  return sanitizedChartData;
};
