import React, { useContext } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import { UserContext } from "./components/UserContext";
import Layout from "./components/Layout";
import Login from "./components/pages/login/Login";
import Profile from "./components/pages/profile/Profile";
import NewPair from "./components/pages/settings/newPair";
import NewAnalysis from "~/components/pages/heatMap/analysis/createNew/index";
import AnalysisList from "~/components/pages/heatMap/analysis/List";
import UserList from "~/components/pages/heatMap/userList";
import UserProfile from "~/components/pages/heatMap/userList/UserProfile";
import NotificationList from "~/components/pages/heatMap/notification/list";
import FundamentalView from "~/components/pages/heatMap/analysis/FundamentalDetails";
import TechnicalView from "~/components/pages/heatMap/analysis/TechnicalDetails";
import OnchainView from "~/components/pages/heatMap/analysis/OnChainDetails";
import HeatMapList from "~/components/pages/heatMap/heatMap/List";
import HeatMapView from "~/components/pages/heatMap/heatMap/view/View";
import Imbalances from "~/components/pages/tools/imbalances/Imbalances";
import FullChart from "~/components/pages/fullChart/FullChart";
import NewSetup from "~/components/pages/setup/createNew";
import SetupList from "~/components/pages/setup/List";
import SetupView from "~/components/pages/setup/view";
import "./assets/scss/styles.scss";
import "~/styles.css";

const App = () => {
  const { user, updateUser } = useContext(UserContext);
  const permissions = user?.role[0]?.permissions;

  const router = createBrowserRouter([
    { path: "/login", element: <Login /> },
    {
      path: "/profile",
      element: (
        <ProtectedRoute>
          <Layout user={user} breadcrumbItems={{ main: "Profile" }}>
            <Profile user={user} updateUser={updateUser} />
          </Layout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <Layout
            user={user}
            breadcrumbItems={{
              main: "HeatMap",
              sub: ["Analysis", "New Analysis"],
            }}
          >
            <NewAnalysis user={user} permissions={permissions} />
          </Layout>
        </ProtectedRoute>
      ),
    },

    {
      path: "/analysisList",
      element: (
        <ProtectedRoute>
          <Layout
            user={user}
            breadcrumbItems={{
              main: "HeatMap",
              sub: ["Analysis List"],
            }}
          >
            <AnalysisList user={user} permissions={permissions} />
          </Layout>
        </ProtectedRoute>
      ),
    },

    {
      path: "/userList",
      element: (
        <ProtectedRoute>
          <Layout
            user={user}
            breadcrumbItems={{
              main: "HeatMap",
              sub: ["User List"],
            }}
          >
            <UserList />
          </Layout>
        </ProtectedRoute>
      ),
    },
    {
      path: "userList/userProfile",
      element: (
        <ProtectedRoute>
          <Layout
            user={user}
            breadcrumbItems={{
              main: "HeatMap",
              sub: ["User profile Detail"],
            }}
          >
            <UserProfile />
          </Layout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/imbalances",
      element: (
        <ProtectedRoute>
          <Layout
            user={user}
            breadcrumbItems={{
              main: "Tools",
              sub: ["Imbalances"],
            }}
          >
            <Imbalances />
          </Layout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/newPair",
      element: (
        <ProtectedRoute>
          <Layout
            user={user}
            breadcrumbItems={{
              main: "Settings",
              sub: ["New Pair"],
            }}
          >
            <NewPair />
          </Layout>
        </ProtectedRoute>
      ),
    },

    {
      path: "/onchain-view/:id",
      element: (
        <ProtectedRoute>
          <Layout
            user={user}
            breadcrumbItems={{
              main: "HeatMap",
              sub: ["Analysis", "Details"],
            }}
          >
            <OnchainView permissions={permissions} />
          </Layout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/technical-view/:id",
      element: (
        <ProtectedRoute>
          <Layout
            user={user}
            breadcrumbItems={{
              main: "HeatMap",
              sub: ["Analysis", "Details"],
            }}
          >
            <TechnicalView user={user} permissions={permissions} />
          </Layout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/fundamental-view/:id",
      element: (
        <ProtectedRoute>
          <Layout
            user={user}
            breadcrumbItems={{
              main: "HeatMap",
              sub: ["Analysis", "Details"],
            }}
          >
            <FundamentalView permissions={permissions} />
          </Layout>
        </ProtectedRoute>
      ),
    },

    {
      path: "/heatMap-list",
      element: (
        <ProtectedRoute>
          <Layout
            user={user}
            breadcrumbItems={{
              main: "HeatMap",
              sub: ["HeatMap List"],
            }}
          >
            <HeatMapList permissions={permissions} />
          </Layout>
        </ProtectedRoute>
      ),
    },

    {
      path: "/heatMap/:id",
      element: (
        <ProtectedRoute>
          <Layout
            user={user}
            breadcrumbItems={{
              main: "HeatMap",
              sub: ["HeatMap List", "Details"],
            }}
          >
            <HeatMapView permissions={permissions} />
          </Layout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/newSetup",
      element: (
        <ProtectedRoute>
          <Layout
            user={user}
            breadcrumbItems={{
              main: "HeatMap",
              sub: ["New Setup"],
            }}
          >
            <NewSetup user={user} permissions={permissions} />
          </Layout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/setupList",
      element: (
        <ProtectedRoute>
          <Layout
            user={user}
            breadcrumbItems={{
              main: "Setup List",
            }}
          >
            <SetupList permissions={permissions} />
          </Layout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/setup",
      element: (
        <ProtectedRoute>
          <Layout
            user={user}
            breadcrumbItems={{
              main: "Setup List",
              sub: ["Details"],
            }}
          >
            <SetupView user={user} permissions={permissions} />
          </Layout>
        </ProtectedRoute>
      ),
    },

    {
      path: "/notification",
      element: (
        <ProtectedRoute>
          <Layout
            user={user}
            breadcrumbItems={{ main: "HeatMap", sub: ["Notification"] }}
          >
            <NotificationList permissions={permissions} />
          </Layout>
        </ProtectedRoute>
      ),
    },

    {
      path: "/fullchart",
      element: (
        <ProtectedRoute>
          <Layout
            user={user}
            breadcrumbItems={{
              main: "Chart",
            }}
          >
            <FullChart />
          </Layout>
        </ProtectedRoute>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
