import React, { useEffect, useRef, useState } from "react";

import { widget } from "~/charting_library";
import { useBlocker } from "react-router-dom";
import { convertChartTypeCodeToName } from "../../utils/converters/convertChartTypeCodeToName";
import { mainChartApiUpdateHelper } from "../../utils/helpers/api/mainChartApiUpdateHelper";
import { createChartConfig } from "../../config/createChartConfig";

import NavBlockerAlert from "../../ui/NavBlockerAlert";
import useLocalStorage from "use-local-storage";

import "../index.css";


export const TVChartContainer = ({
  chartData,
  setChartData,
  theme,
  setTheme,
  favorites,
  setFavorites,
  layoutList,
  setLayoutList,
  templateList,
  setTemplateList,
}) => {
  const chartContainerRef = useRef();
  const { baseChartConfig, specificConfigs } = createChartConfig("mainChart");

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      chartHasChanged && currentLocation.pathname !== nextLocation.pathname
  );
  const [openAlert, setOpenAlert] = useState(true);
  const [chartHasChanged, setChartHasChanged] = useState(false);
  const [nearRealTimeState, setNearRealTimeState] = useLocalStorage(
    "full_chart_near_real_time",
    null
  );

  const saveFavoritesToLocalStorage = () => {
    setChartHasChanged(true);
  };

  const updateChartByAlertEvent = () => {
    mainChartApiUpdateHelper.event(
      "main_chart_data",
      JSON.stringify(nearRealTimeState)
    );
  };

  useEffect(() => {
    setNearRealTimeState({
      chartData: chartData,
      chartTheme: theme,
      favorites: favorites,
    });
  }, [chartData, theme, favorites, chartHasChanged]);

  useEffect(() => {
    const onBeforeUnload = (event) => {
      event.preventDefault();
      mainChartApiUpdateHelper.event(
        "main_chart_data",
        JSON.stringify(nearRealTimeState)
      );
    };
    chartHasChanged && window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [nearRealTimeState]);

  useEffect(() => {
    const widgetOptions = {
      ...baseChartConfig?.defaultProps,
      container: chartContainerRef.current,
      datafeed: baseChartConfig?.createDatafeed(
        baseChartConfig?.datafeedUrl?.binance
      ),
      disabled_features: specificConfigs?.disabledFeatures,
      enabled_features: specificConfigs?.enabledFeatures,
      theme: theme,
      symbol: null,
      interval: "D",
      saved_data: chartData,

      save_load_adapter: {
        charts: layoutList,
        chartTemplates: templateList,

        getAllCharts: function () {
          return Promise.resolve(this.charts);
        },
        saveChart: function (chartData) {
          if (!chartData.id) {
            chartData.id = Math.random().toString();
          } else {
            this.removeChart(chartData.id);
          }

          const savedChartData = {
            ...chartData,
            id: chartData.id,
            timestamp: Math.round(Date.now() / 1000),
          };

          if (this.charts.push(savedChartData)) {
            setLayoutList(this.charts);
            mainChartApiUpdateHelper.event(
              "layouts",
              JSON.stringify(this.charts)
            );
            setChartHasChanged(true);
          }
        },
        removeChart: function (id) {
          this.charts.filter((item) => item.id !== id);

          for (var i = 0; i < this.charts.length; ++i) {
            if (this.charts[i].id === id) {
              this.charts.splice(i, 1);
            }
          }

          setLayoutList(this.charts);
          mainChartApiUpdateHelper.event(
            "layouts",
            JSON.stringify(this.charts)
          );
          setChartHasChanged(true);
        },
        getChartContent: function (id) {
          for (var i = 0; i < this.charts.length; ++i) {
            if (this.charts[i].id === id) {
              return Promise.resolve(this.charts[i].content);
            }
          }

          return Promise.reject();
        },

        async getAllChartTemplates() {
          return this.chartTemplates.map((x) => x.name);
        },
        async saveChartTemplate(templateName, content) {
          const theme = this.chartTemplates.find(
            (x) => x.name === templateName
          );

          if (theme) {
            theme.content = content;
            setChartHasChanged(true);
            mainChartApiUpdateHelper.event(
              "template",
              JSON.stringify(this.chartTemplates)
            );
          } else {
            this.chartTemplates.push({ name: templateName, content });
            setTemplateList(this.chartTemplates);
            mainChartApiUpdateHelper.event(
              "template",
              JSON.stringify(this.chartTemplates)
            );
            setChartHasChanged(true);
          }
        },
        async removeChartTemplate(templateName) {
          this.chartTemplates = this.chartTemplates.filter(
            (x) => x.name !== templateName
          );
          this.isDirty = true;
          setTemplateList(this.chartTemplates);
          mainChartApiUpdateHelper.event(
            "template",
            JSON.stringify(this.chartTemplates)
          );
          setChartHasChanged(true);
        },
        async getChartTemplateContent(templateName) {
          const theme = {};
          const content = this.chartTemplates.find(
            (x) => x.name === templateName
          )?.content;

          if (content) {
            theme.content = structuredClone(content);
          }

          return theme;
        },
      },

      context_menu: {
        items_processor: (defaultItems, actionFactory) => {
          const darkModeItem = actionFactory.createAction({
            label: "Dark",
            icon: `<?xml version='1.0' ?><!DOCTYPE svg  PUBLIC '-//W3C//DTD SVG 1.1//EN'  'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'><svg enable-background='new 0 0 512 512' height='512px' id='Layer_1' version='1.1' viewBox='0 0 512 512' width='512px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><path d='M349.852,343.15c-49.875,49.916-131.083,49.916-181,0c-49.916-49.918-49.916-131.125,0-181.021  c13.209-13.187,29.312-23.25,47.832-29.812c5.834-2.042,12.293-0.562,16.625,3.792c4.376,4.375,5.855,10.833,3.793,16.625  c-12.542,35.375-4,73.666,22.25,99.917c26.209,26.228,64.5,34.75,99.916,22.25c5.792-2.062,12.271-0.582,16.625,3.793  c4.376,4.332,5.834,10.812,3.771,16.625C373.143,313.838,363.06,329.941,349.852,343.15z M191.477,184.754  c-37.438,37.438-37.438,98.354,0,135.771c40,40.021,108.125,36.416,143-8.168c-35.959,2.25-71.375-10.729-97.75-37.084  c-26.375-26.354-39.333-61.771-37.084-97.729C196.769,179.796,194.039,182.192,191.477,184.754z' fill='#929292'/></svg>`,
            hint: "Reset theme to (Dark mode)",
            onExecute: () => {
              setTheme("dark");
              tvWidget.changeTheme("dark");
              setChartHasChanged(true);
            },
          });

          const lightModeItem = actionFactory.createAction({
            label: "Light",
            icon: `<?xml version='1.0' ?><!DOCTYPE svg  PUBLIC '-//W3C//DTD SVG 1.1//EN'  'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'><svg enable-background='new 0 0 512 512' height='512px' id='Layer_1' version='1.1' viewBox='0 0 512 512' width='512px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g><g><path d='M256,144c-61.75,0-112,50.25-112,112s50.25,112,112,112s112-50.25,112-112S317.75,144,256,144z M256,336    c-44.188,0-80-35.812-80-80c0-44.188,35.812-80,80-80c44.188,0,80,35.812,80,80C336,300.188,300.188,336,256,336z M256,112    c8.833,0,16-7.167,16-16V64c0-8.833-7.167-16-16-16s-16,7.167-16,16v32C240,104.833,247.167,112,256,112z M256,400    c-8.833,0-16,7.167-16,16v32c0,8.833,7.167,16,16,16s16-7.167,16-16v-32C272,407.167,264.833,400,256,400z M380.438,154.167    l22.625-22.625c6.25-6.25,6.25-16.375,0-22.625s-16.375-6.25-22.625,0l-22.625,22.625c-6.25,6.25-6.25,16.375,0,22.625    S374.188,160.417,380.438,154.167z M131.562,357.834l-22.625,22.625c-6.25,6.249-6.25,16.374,0,22.624s16.375,6.25,22.625,0    l22.625-22.624c6.25-6.271,6.25-16.376,0-22.625C147.938,351.583,137.812,351.562,131.562,357.834z M112,256    c0-8.833-7.167-16-16-16H64c-8.833,0-16,7.167-16,16s7.167,16,16,16h32C104.833,272,112,264.833,112,256z M448,240h-32    c-8.833,0-16,7.167-16,16s7.167,16,16,16h32c8.833,0,16-7.167,16-16S456.833,240,448,240z M131.541,154.167    c6.251,6.25,16.376,6.25,22.625,0c6.251-6.25,6.251-16.375,0-22.625l-22.625-22.625c-6.25-6.25-16.374-6.25-22.625,0    c-6.25,6.25-6.25,16.375,0,22.625L131.541,154.167z M380.459,357.812c-6.271-6.25-16.376-6.25-22.625,0    c-6.251,6.25-6.271,16.375,0,22.625l22.625,22.625c6.249,6.25,16.374,6.25,22.624,0s6.25-16.375,0-22.625L380.459,357.812z' fill='#929292'/></g></g></svg>`,
            hint: "Reset theme to (Light mode)",
            onExecute: () => {
              setTheme("light");
              tvWidget.changeTheme("light");
              setChartHasChanged(true);
            },
          });
          const items = [...defaultItems.slice(), darkModeItem, lightModeItem];
          return items;
        },
      },

      favorites: {
        intervals: favorites[0]?.intervals ?? [],
        indicators: favorites[0]?.indicators ?? [],
        drawingTools: favorites[0]?.drawingTools ?? [],
        chartTypes: convertChartTypeCodeToName(favorites[0]?.chartTypes) ?? [],
      },

      settings_adapter: {
        initialSettings: {
          "loadChartDialog.favorites": favorites[1]?.loadChartDialog ?? [],
        },
        setValue: function (key, value) {
          setFavorites((prevFavorites) => {
            const updatedFavorites = [...prevFavorites]; // Create a copy of the previous state
            if (key === "chart.favoriteDrawings") {
              updatedFavorites[0] = {
                ...updatedFavorites[0],
                drawingTools: JSON.parse(value),
              };

              saveFavoritesToLocalStorage(updatedFavorites);
            } else if (key === "chart.favoriteLibraryIndicators") {
              updatedFavorites[0] = {
                ...updatedFavorites[0],
                indicators: JSON.parse(value),
              };
              saveFavoritesToLocalStorage(updatedFavorites);
            } else if (key === "IntervalWidget.quicks") {
              updatedFavorites[0] = {
                ...updatedFavorites[0],
                intervals: JSON.parse(value),
              };
              saveFavoritesToLocalStorage(updatedFavorites);
            } else if (key === "StyleWidget.quicks") {
              updatedFavorites[0] = {
                ...updatedFavorites[0],
                chartTypes: JSON.parse(value),
              };
              saveFavoritesToLocalStorage(updatedFavorites);
            } else if (key === "loadChartDialog.favorites") {
              updatedFavorites[1] = {
                ...updatedFavorites[1],
                loadChartDialog: value,
              };
              saveFavoritesToLocalStorage(updatedFavorites);
            }

            return updatedFavorites;
          });
        },
        removeValue: function (key) {
          console.log(`remove value: ${key}`);
        },
      },
    };

    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        tvWidget.save((data) => {
          setChartData(data);
        });
        tvWidget.subscribe("undo_redo_state_changed", () => {
          tvWidget.save((data) => {
            setChartHasChanged(true);
            setChartData(data);
          });
        });
      });
    });
    return () => {
      tvWidget.remove();
    };
  }, []);

  return (
    <>
      {blocker.state === "blocked" && (
        <NavBlockerAlert
          open={openAlert}
          setOpen={setOpenAlert}
          blocker={blocker}
          updateChartByAlertEvent={updateChartByAlertEvent}
        />
      )}

      <div ref={chartContainerRef} className={"TVChartContainer"} />
    </>
  );
};
