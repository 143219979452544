import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { widget } from "~/charting_library";
import { createChartConfig } from "~/components/child/tradingViewMainChart/config/createChartConfig.js";

import axios from "axios";

import "../index.css";

export const TVChartContainer = forwardRef(
  ({ pair, setIsPlotZones, imbalanceValue, theme }, chartRef) => {

    const chartContainerRef = useRef();
    const { baseChartConfig, specificConfigs } =
      createChartConfig("imbalances");
    const tvWidgetRef = useRef();

    useImperativeHandle(chartRef, () => ({
      plotZonesMethod,
      clearZonesMethod,
    }));

    const getAllShapeData = async (url) => {
      let result = null;
      try {
        const res = await axios.get(url);
        if (res.status === 200) {
          result = res.data;
        } else {
          console.log("imbalances : get data error");
        }
      } catch (error) {
        console.error(error.error);
      }

      return result;
    };

    function draw_zones(zones) {
      let chart = tvWidgetRef.current.chart();

      for (const zone of zones) {
        let volume_in_milions = Math.round(
          (zone.volume * (zone.points[0].price + zone.points[1].price)) /
            2 /
            1000000
        );
        let imb = Math.abs(zone.imbalance);
        let score = imb * volume_in_milions;

        if (imb < imbalanceValue) {
          continue;
        }

        let color =
          "rgba(155, 210, 160, " +
          Math.abs(40 / (100 - zone.imbalance) / 100) +
          ")";
        if (zone.side == "Sell") {
          color =
            "rgba(250, 80, 95, " +
            Math.abs(40 / (100 - Math.abs(zone.imbalance)) / 100) +
            ")";
        }

        chart.createMultipointShape(zone.points, {
          shape: "rectangle",
          text: volume_in_milions + "M",
          lock: true,
          disableUndo: true,
          disableSelection: true,
          disableSave: true,

          overrides: {
            backgroundColor: color,
            linewidth: 0,
            textColor: "#291b00",
            fontSize: "12",
            showLabel: true,
            horzLabelsAlign: "center",
            vertLabelsAlign: "middle",
          },
        });
      }
    }

    function draw_rotations(rotations) {
      let chart = tvWidgetRef.current.chart();

      for (const rotation of rotations) {
        chart.createMultipointShape(rotation.points, {
          shape: "rectangle",
          lock: true,
          disableUndo: true,
          disableSelection: true,
          disableSave: true,
          overrides: {
            backgroundColor: "rgb(81, 200, 255 , 50%)",
            linewidth: 0,
          },
        });
      }
    }

    const plotZonesMethod = async () => {
      if (tvWidgetRef.current) {
        let resultZones = await getAllShapeData(
          `/json/imbalances/${pair.toLowerCase()}.json`
        );
        try {
          draw_zones(resultZones);
        } catch (error) {
          console.log(`draw_zones error on ${pair} => `, error);
        }
      }
      if (tvWidgetRef.current) {
        let resultRotations = await getAllShapeData(
          `/json/imbalances/${pair.toLowerCase()}Rotations.json`
        );
        try {
          draw_rotations(resultRotations);
        } catch (error) {
          console.log(`resultRotations error on ${pair} => `, error);
        }
      }
    };

    const clearZonesMethod = () => {
      if (tvWidgetRef.current && tvWidgetRef.current.activeChart()) {
        let allShape = tvWidgetRef.current.activeChart().getAllShapes();
        allShape.forEach((shape) => {
          tvWidgetRef.current.activeChart().removeEntity(shape.id);
        });
      }
    };

    useEffect(() => {
      const widgetOptions = {
        ...baseChartConfig?.defaultProps,
        container: chartContainerRef.current,
        datafeed: baseChartConfig?.createDatafeed(
          baseChartConfig?.datafeedUrl?.binance
        ),
        disabled_features: specificConfigs?.disabledFeatures,
        enabled_features: specificConfigs?.enabledFeatures,
        theme: theme,
        symbol: pair,
        interval: "D",
      };

      const tvWidget = new widget(widgetOptions);
      tvWidgetRef.current = tvWidget;

      tvWidget.onChartReady(() => {
        tvWidget.headerReady().then(() => {
          tvWidget
            .activeChart()
            .onIntervalChanged()
            .subscribe(null, (interval) => {
              try {
                clearZonesMethod();
                setIsPlotZones(false);
              } catch (error) {
                setIsPlotZones(true);
                console.log("Change Interval Inbalances Error => ", error);
              }
            });
        });
      });

      return () => {
        tvWidget.remove();
      };
    }, [pair]);

    return <div ref={chartContainerRef} className={"TVChartContainer"} />;
  }
);
