import { validateInput } from "../helpers/tools/validateInput";

export class TimeFrameCalculator {
  formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  getUnixTime(date) {
    return new Date(date).getTime() / 1000;
  }

  calculateDate(daysOffset, baseDate = new Date()) {
    const date = new Date(baseDate);
    date.setDate(date.getDate() + daysOffset);
    const unixTimeWithFullDate = this.getUnixTime(date);
    const unixTimeWithOnlyDay = this.getUnixTime(this.formatDate(date));
    return daysOffset === 0 || daysOffset === -0 ? unixTimeWithFullDate : unixTimeWithOnlyDay;
}

  beforeTime(beforeDays, createdAt, baseDateType) {
    const functionName = this.beforeTime.name
    validateInput(beforeDays, "beforeDays", functionName);
    validateInput(baseDateType, "baseDateType", functionName);

    const baseDate =
      baseDateType === "create" ? new Date() : new Date(createdAt);
    return this.calculateDate(-beforeDays, baseDate);
  }

  afterTime(afterDays, createdAt, baseDateType) {
    const functionName = this.afterTime.name
    validateInput(afterDays, "afterDays", functionName);
    validateInput(baseDateType, "baseDateType", functionName);

    const baseDate =
      baseDateType === "create" ? new Date() : new Date(createdAt);
    return this.calculateDate(afterDays, baseDate);
  }
}
