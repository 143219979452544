import React, { createElement } from "react";

import { tv } from "tailwind-variants";
import { Spinner } from "~/assets/icon/svgJsx/Spinner";

const buttonVariants = tv({
  base: "flex items-center justify-center border-none p-2 rounded-xl cursor-pointer text-center no-underline font-roboto",
  variants: {
    color: {
      primary: "text-sky-light bg-sky-80",
      outline: "border border-solid text-sky-80 border-sky-80 border bg-none",
      danger:
        "text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500",
    },
    disabled: {
      true: "opacity-70 cursor-auto",
    },
  },
});

const Button = ({ isLoading, onClick, children, icon, variant, ...props }) => {
  const Icon = isLoading ? Spinner : icon;
  const classes = buttonVariants({ color: variant, disabled: props.disabled });

  return (
    <button
      onClick={onClick}
      {...props}
      className={`${classes} ${props.className || ""}`}
    >
      {Icon &&
        createElement(Icon, {
          className: "size-4 mr-2",
        })}
      {children}
    </button>
  );
};

export default Button;
