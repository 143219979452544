import React, { useState } from "react";

import WarningOne from "~/assets/icon/svgJsx/WarningOne";

const Description = ({ setDescription, description, limitNumber }) => {
  const [limitationExceeded, setLimitationExceeded] = useState(false);
  return (
    <div className="flex flex-col gap-1">
      <div className="description-cstm-one">
        <div
          className={`select-cstm-one description-cstm-one__box ${
            limitationExceeded && "description-cstm-one__box-limitationExceeded"
          }`}
        >
          <div className="select-cstm-one__title">Description</div>

          <textarea
            value={description}
            onChange={(e) => {
              if (description?.length <= limitNumber - 2)
                setDescription(e.target?.value);
              else setLimitationExceeded(true);
            }}
            maxLength={limitNumber}
            onKeyDown={(e) => {
              if (e.key === "Backspace" && limitationExceeded) {
                e.preventDefault();
                setDescription(description?.slice(0, -1));
                setLimitationExceeded(false);
              }
            }}
            name=""
          ></textarea>
        </div>
      </div>

      {limitNumber && (
        <div className="description-cstm-one__limit-character-description">
          <WarningOne />
          {limitNumber} character
        </div>
      )}
    </div>
  );
};

export default Description;
