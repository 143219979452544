import React, { useState, useEffect } from "react";
import Pagination from "rc-pagination/lib/Pagination";
import { NavLink, useSearchParams } from "react-router-dom";

import { usersApi } from "~/api/dashboard/usersApi";

import ArrowLeftSvg from "~/assets/icon/svgJsx/ArrowLeftSvg";
import ArrowRightSvg from "~/assets/icon/svgJsx/ArrowRightSvg";
import profileImg from "~/assets/icon/profile.svg";

import { Loading } from "~/components/child/ui/Loading";

import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";

import { getTechnicalStatusTheme } from "~/utils/getTechnicalStatusTheme";

const InfoBox = ({ title, value }) => (
  <div className="flex flex-col items-start rounded-lg py-4 text-lg text-storm-key bg-base pl-8 gap-2">
    {title}
    <span className="text-[#4F5B67] text-xl">{value}</span>
  </div>
);

const UserProfile = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [userInfo, setUserInfo] = useState({
    data: undefined,
    analytics: undefined,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUserData = async (id) => {
    try {
      const result = await usersApi.single(id);
      if (result?.data) {
        setUserInfo((prevUserInfo) => ({
          ...prevUserInfo,
          data: result.data,
        }));
      } else if (result.error) {
        setError(result.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError(error);
      setIsLoading(false);
    }
  };

  const fetchUserAnalytics = async (id, page) => {
    setTableLoading(true);
    try {
      const result = await usersApi.userAnalytics(id, page);
      if (result?.data) {
        setUserInfo((prevUserInfo) => ({
          ...prevUserInfo,
          analytics: result.data,
        }));
      } else if (result.error) {
        setError(result.error);
      }
      setTableLoading(false);
    } catch (error) {
      console.error("Error fetching user analytics:", error);
      setError(error);
      setTableLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchUserData(id);
    }
  }, [id]);

  useEffect(() => {
    fetchUserAnalytics(id, currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="main__router__container">
      <div className="main__router__container__list-box-container max-h-[86vh] overflow-x-hidden overflow-y-auto scrollbar-style scrollbar-style-thumb">
        {!isLoading && userInfo ? (
          <div className="flex flex-col mr-1.5 gap-4">
            <div className="grid grid-cols-3 gap-4">
              <div className="flex items-center rounded-lg py-4 bg-base pl-8 gap-2">
                <span className="bg-sky-20 border border-sky-80 rounded-full flex items-center justify-center relative overflow-hidden p-3">
                  <img
                    className=" w-7 h-7"
                    src={profileImg}
                    alt="profile icon"
                  />
                </span>
                <div className="flex text-storm-key text-xl flex-col">
                  {userInfo?.data?.first_name} {userInfo?.data?.last_name}
                  <span className="text-[1rem] text-[#4F5B67] font-roboto">
                    Analyzer
                  </span>
                </div>
              </div>
              <InfoBox
                title="Win Rate"
                value={
                  userInfo?.data?.win_rate !== null
                    ? `${parseInt(Number(userInfo?.data?.win_rate) * 100)}${
                        userInfo?.data?.win_rate !== 0 && "%"
                      }`
                    : "-"
                }
              />
              <InfoBox title="R.R" value={userInfo?.data?.rr || "-"} />
            </div>
            <div className="grid grid-cols-5 gap-4">
              <InfoBox
                title="Total analyze"
                value={
                  userInfo.data?.technical.setup_count +
                  userInfo.data.technical.analytic_count +
                  userInfo?.data?.fundamental +
                  userInfo?.data?.on_chain
                }
              />
              <InfoBox
                title="Setup Technical"
                value={userInfo.data?.technical.setup_count}
              />
              <InfoBox
                title="Analysis Technical"
                value={userInfo.data?.technical.analytic_count}
              />
              <InfoBox
                title="Fundamental"
                value={userInfo?.data?.fundamental}
              />
              <InfoBox title="On-Chain" value={userInfo?.data?.on_chain} />
            </div>
            {userInfo?.analytics?.data?.length > 0 && (
              <div className="flex flex-col mt-6 gap-2">
                <h3 className=" text-storm-70  text-lg">
                  List of Technical analysis
                </h3>
                {!tableLoading ? (
                  <>
                    <table className="list-table-cstm-one__content-table">
                      <thead>
                        <tr>
                          <th>Row</th>
                          <th>Pair</th>
                          <th>Date</th>
                          <th>R.R</th>
                          <th>Status</th>
                          <th>Detail</th>
                        </tr>
                      </thead>

                      <tbody>
                        {userInfo?.analytics?.data?.map((item, index) => {
                          const date = new Date(item?.date);

                          return (
                            <tr className=" h-14" key={item?.uuid}>
                              <td className="">
                                {(currentPage - 1) * 10 + index + 1}
                              </td>

                              <td className="">{item?.pair.pair}</td>

                              <td className="flex flex-col">
                                {date.toISOString().split("T")[0]}
                                <span className=" text-xs">
                                  {
                                    date
                                      .toISOString()
                                      .split("T")[1]
                                      .split(".")[0]
                                  }
                                </span>
                              </td>
                              <td>{item?.rr || "-"}</td>
                              <td>
                                <span
                                  className={`border py-2 px-4 text-base rounded-3xl ${getTechnicalStatusTheme(
                                    item?.status
                                  )}`}
                                >
                                  {item?.status
                                    ? changeWordFormatToTitleCase(item?.status)
                                    : "No Touch"}
                                </span>
                              </td>

                              <td className="list-table-cstm-one__content-table__details-arrow">
                                <NavLink
                                  className="flex justify-center items-center"
                                  to={`/technical-view/${item?.uuid}`}
                                >
                                  <ArrowRightSvg />
                                </NavLink>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {userInfo?.analytics?.meta?.total > 10 && (
                      <div className="pagination-one">
                        <Pagination
                          current={currentPage}
                          total={userInfo?.analytics?.meta?.total}
                          pageSize={10}
                          onChange={handlePageChange}
                          showSizeChanger={false}
                          prevIcon={<ArrowLeftSvg />}
                          nextIcon={<ArrowRightSvg />}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="m-auto mt-[15%]">
                    <Loading size="large" />
                  </div>
                )}
              </div>
            )}
          </div>
        ) : !isLoading ? (
          <div className="empty-analysis">
            <h4 className="empty-analysis__title">No results!</h4>
          </div>
        ) : (
          <Loading size="large" />
        )}
      </div>
    </div>
  );
};

export default UserProfile;
