import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { authApi } from "../api/auth/authApi";

import Tooltip from "./child/ui/Tooltip";

import arrowRight from "../assets/icon/arrows/arrow-right-2.svg";
import profileImg from "../assets/icon/profile.svg";
import logoutIcon from "../assets/icon/logout.svg";
import ArrowBottomSvg from "../assets/icon/svgJsx/ArrowDownSvg";

const Header = ({ breadcrumbItems, user }) => {
  const navigate = useNavigate();
  const [showProfileTooltip, setShowProfileTooltip] = useState(false);

  const headerBoxRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        headerBoxRef.current &&
        !headerBoxRef.current.contains(event.target)
      ) {
        setShowProfileTooltip(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const logoutHandler = () => {
    const body = {
      email: user.email,
    };
    authApi.logout(body).then((res) => {
      if (res) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessTokenExpiration");
        localStorage.removeItem("refreshTokenExpiration");
        window.location.href = "/login";
      }
    });
  };

  return (
    <header className="header">
      <div
        ref={headerBoxRef}
        onClick={(e) => {
          e.preventDefault();
          setShowProfileTooltip(false);
        }}
        className="header__box"
      >
        <div className="header__box__flex-left">
          <div className="header__box__flex-left__path">
            <div className="header__box__flex-left__path__name-nav-item">
              <span>{breadcrumbItems?.main}</span>
            </div>

            {breadcrumbItems?.sub?.map((item, index) => (
              <div
                className="header__box__flex-left__path__name-nav-item"
                key={index}
              >
                <div className="header__box__flex-left__path__arrow">
                  <i>
                    <img src={arrowRight} alt="" />
                  </i>
                </div>
                <div className="header__box__flex-left__path__step">
                  <span>{item}</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="header__box__flex-right">
          {user?.role[0]?.permissions?.new_analytic && (
            <div className="header__box__flex-right__btn">
              <button onClick={() => navigate("/")}>New analysis</button>
            </div>
          )}

          <div className="header__box__flex-right__avatar">
            <i>
              <img src={profileImg} alt="profile icon" />
            </i>
          </div>

          <div className="header__box__flex-right__userInfo">
            {user?.first_name} {user?.last_name}
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowProfileTooltip(!showProfileTooltip);
              }}
              className="header__box__flex-right__userInfo__profile"
            >
              <ArrowBottomSvg />
              {showProfileTooltip && (
                  <Tooltip
                    children={[
                      {
                        onClickHandler: () => navigate("/profile"),
                        elements: (
                          <>
                            <img
                              className="w-5 h-5"
                              src={profileImg}
                              alt="profile icon"
                            />
                            <span className="border-b border-storm-20 text-storm-key flex font-yekanBakh text-base font-bold text-left w-full leading-6">
                              Profile
                            </span>
                          </>
                        ),
                      },
                      {
                        elements: (
                          <>
                            <img
                              className="w-5 h-5"
                              src={logoutIcon}
                              alt="logout icon"
                            />
                            <button
                              className="text-storm-key flex font-yekanBakh text-base font-bold text-left w-full leading-6"
                              onClick={() => logoutHandler()}
                            >
                              Logout
                            </button>
                          </>
                        ),
                      },
                    ]}
                  />
              )}
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
