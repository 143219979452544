export const onChangeCheckboxes = (propertyArray, item) => {
  const updatedPropertyArray = [...propertyArray];

  if (propertyArray.some((obj) => obj["key"] === item.key)) {
    const index = propertyArray.findIndex((obj) => obj["key"] === item.key);
    updatedPropertyArray.splice(index, 1);
  } else {
    updatedPropertyArray.push(item);
  }
  return updatedPropertyArray;
};
