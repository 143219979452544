import React, { useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";
import CopySvg from "~/assets/icon/svgJsx/CopySvg";
import weightSvg from "~/assets/icon/weight.svg";

const PointsPreview = ({
  label,
  value,
  riskToReward,
  note,
  noteInTooltip = false,
  className,
  info,
  user,
  showAnalyzers,
  weight,
}) => {
  const [showNote, setShowNote] = useState(false);
  return (
    <div className={`technicalPointPreview ${className}`}>
      <div className="technicalPointPreview__info">
        <div className="technicalPointPreview__info__value">
          <span
            className={` technicalPointPreview__info__value-${label.toLowerCase()}`}
          >
            {label}
          </span>
          <span>{value}</span>
          {weight && (
            <div className="flex items-center text-xs gap-2">
              <img src={weightSvg} className="w-3 h-3" alt="weight icon" />
              {weight}%
            </div>
          )}
          {riskToReward && (
            <div className="btn-ocean-cstm">RR {riskToReward}</div>
          )}
        </div>
        <div className="flex items-center gap-2">
          {info && (
            <div className="flex items-center gap-2 font-roboto text-xs font-light">
              {info?.positionType && (
                <span className=" bg-storm-20 text-storm-50 py-[0.375rem] px-2 rounded-2xl">
                  {changeWordFormatToTitleCase(info?.positionType)}
                </span>
              )}
              {info?.technicalTypes?.length > 0 &&
                info?.technicalTypes?.map((technicalType, index) => (
                  <span
                    key={index}
                    className=" bg-storm-20 text-storm-50 py-[0.375rem] px-2 rounded-2xl"
                  >
                    {changeWordFormatToTitleCase(technicalType.technical_type)}
                  </span>
                ))}
              {info?.timeFrame && (
                <span className=" bg-storm-20 text-storm-50 py-[0.375rem] px-2 rounded-2xl">
                  {info?.timeFrame}
                </span>
              )}
              {info?.analyser && (
                <span className=" bg-storm-20 text-storm-50 py-[0.375rem] px-2 rounded-2xl">
                  {info?.analyser}
                </span>
              )}
            </div>
          )}
          {note && (
            <div className="technicalPointPreview__info__note">
              <button
                onClick={() => {
                  if (!noteInTooltip) setShowNote(!showNote);
                }}
                disabled={note?.length === 0 || !note}
                data-tooltip-id={note}
                className={
                  note?.length !== 0 && note
                    ? showNote
                      ? `technicalPointPreview__info__note-hide`
                      : `technicalPointPreview__info__note-show`
                    : ""
                }
              >
                {note?.length !== 0 && note
                  ? showNote
                    ? "Hide note"
                    : "Show note"
                  : "No note"}
                <CopySvg />
              </button>
              {note?.length !== 0 && note && noteInTooltip && (
                <ReactTooltip
                  id={note}
                  place="right"
                  multiline={true}
                  content={
                    <div className="technicalPointPreview__info__note__tooltip">
                      <div className="technicalPointPreview__info__note__tooltip__title">
                        <span>{label}</span>
                        <span>{value}</span>
                      </div>
                      <p className="technicalPointPreview__info__note__tooltip__description">
                        {note}
                      </p>
                    </div>
                  }
                />
              )}
            </div>
          )}
          {user && showAnalyzers && (
            <span className="text-storm-50 text-xs font-roboto py-1.5 px-2.5 rounded-3xl bg-storm-20">
              {user}
            </span>
          )}
        </div>
      </div>
      {!noteInTooltip && showNote && (
        <p className="technicalPointPreview__showNote">{note}</p>
      )}
    </div>
  );
};

export default PointsPreview;
