import React, { useEffect, useRef, useState } from "react";

import { widget } from "~/charting_library";
import { WidgetCreator } from "../../../utils/classes/widgetCreator";
import { convertIntervalToTradingViewFormat } from "../../../utils/converters/convertTimeFrameToTradingViewFormat";
import { createTradeBoundariesWidgetTimeRange } from "../../../utils/creators/createTradeBoundariesWidgetTimeRange";
import { createChartTimeFrame } from "../../../utils/creators/createChartTimeFrame";
import { setIntervalChartSource } from "../../../utils/chartSourceModifiers/setIntervalChartSource";
import { createPairDataConfig } from "../../../utils/creators/createPairDataConfig";
import { plotVerticalLineAtCreationTime } from "../../../utils/helpers/plotting/plotVerticalLineAtCreationTime";
import { setThemeChartSource } from "../../../utils/chartSourceModifiers/setThemeChartSource";
import { createChartConfig } from "../../../config/createChartConfig";
import { convertChartTimeZone } from "../../../utils/converters/convertChartTimeZone";

import "../../index.css";

export const TVChartContainer = React.memo(
  ({
    ep,
    sl,
    tp,
    pairDetail,
    initChartData,
    initInterval,
    createdAt,
    baseDateType,
    theme,
  }) => {
  
    const chartContainerRef = useRef();
    const { baseChartConfig, specificConfigs } = createChartConfig("technical");
    const [interval, setInterval] = useState(
      convertIntervalToTradingViewFormat(initInterval)
    );

    const parsedChartData = JSON.parse(JSON.parse(initChartData));
    const modifyChartDataInterval =
      setIntervalChartSource(parsedChartData, interval) ?? null;
    const modifyChartDataTheme = setThemeChartSource(
      modifyChartDataInterval,
      theme
    );
    const finalChartData = convertChartTimeZone(modifyChartDataTheme, 1);
    const { timeFrom: timeFrameLoadChartFrom, timeTo: timeFrameLoadChartTo } =
      createChartTimeFrame(interval, createdAt.split(" ")[0]);
    const {
      base_symbol: baseSymbol,
      quote_symbol: quoteSymbol,
      exchange,
    } = pairDetail;
    const { pair, dataFeed: datafeedUrl } = createPairDataConfig(
      baseSymbol,
      quoteSymbol,
      exchange
    );

    useEffect(() => {
      const widgetOptions = {
        ...baseChartConfig?.defaultProps,
        container: chartContainerRef.current,
        datafeed: baseChartConfig?.createDatafeed(datafeedUrl),
        disabled_features: specificConfigs?.detail?.disabledFeatures,
        enabled_features: specificConfigs?.detail?.enabledFeatures,
        theme: theme,
        symbol: pair,
        interval: interval,
        timeframe: { from: timeFrameLoadChartFrom, to: timeFrameLoadChartTo },
        saved_data: finalChartData,
      };

      const tvWidget = new widget(widgetOptions);
      const createWidget = new WidgetCreator(tvWidget);

      tvWidget.onChartReady(() => {
        tvWidget.headerReady().then(() => {
          if (ep && ep.length > 0) {
            for (const item of ep) {
              const type = item?.max_amount === 0 ? "single" : "range";
              if (type === "single") {
                createWidget.trendLine(
                  item?.base_amount.toString(),
                  createTradeBoundariesWidgetTimeRange(
                    interval,
                    createdAt,
                    baseDateType
                  ),
                  "ep"
                );
              }
              if (type === "range") {
                createWidget.rectangle(
                  [item?.base_amount.toString(), item?.max_amount.toString()],
                  createTradeBoundariesWidgetTimeRange(
                    interval,
                    createdAt,
                    baseDateType
                  ),
                  "ep"
                );
              }
            }
          }

          if (sl && sl.length > 0) {
            for (const item of sl) {
              const type = item?.max_amount === 0 ? "single" : "range";
              if (type === "single") {
                createWidget.trendLine(
                  item?.base_amount.toString(),
                  createTradeBoundariesWidgetTimeRange(
                    interval,
                    createdAt,
                    baseDateType
                  ),
                  "sl"
                );
              }
              if (type === "range") {
                createWidget.rectangle(
                  [item?.base_amount.toString(), item?.max_amount.toString()],
                  createTradeBoundariesWidgetTimeRange(
                    interval,
                    createdAt,
                    baseDateType
                  ),
                  "sl"
                );
              }
            }
          }

          if (tp && tp.length > 0) {
            for (const item of tp) {
              const type = item?.max_amount === 0 ? "single" : "range";
              if (type === "single") {
                createWidget.trendLine(
                  item?.base_amount.toString(),
                  createTradeBoundariesWidgetTimeRange(
                    interval,
                    createdAt,
                    baseDateType
                  ),
                  "tp"
                );
              }
              if (type === "range") {
                createWidget.rectangle(
                  [item?.base_amount.toString(), item?.max_amount.toString()],
                  createTradeBoundariesWidgetTimeRange(
                    interval,
                    createdAt,
                    baseDateType
                  ),
                  "tp"
                );
              }
            }
          }

          // show created at by vertical_line => style :dashed
          plotVerticalLineAtCreationTime(createdAt, createWidget);

          tvWidget
            .activeChart()
            .onIntervalChanged()
            .subscribe(null, (interval) => {
              setInterval(convertIntervalToTradingViewFormat(interval));
            });
        });
      });

      return () => {
        tvWidget.remove();
      };
    }, [finalChartData, interval]);

    return <div ref={chartContainerRef} className={"TVChartContainer"} />;
  }
);
