import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import ImageSlider from "~/components/child/ui/ImageSlider";
import Button from "~/components/child/ui/Button";
import { Loading } from "~/components/child/ui/Loading";
import Accordion from "~/components/child/ui/Accordion";
import { PairsSymbol } from "~/components/child/Symbols";
import TechnicalPointPreview from "~/components/child/PointsPreview";
import FundamentalEntry from "~/components/child/FundamentalEntry";
import { TVChartContainer } from "~/components/child/tradingViewMainChart/tvChartContainer/heatMap/view/TVChartContainer";
import DataFailureAlert from "~/components/child/tradingViewMainChart/ui/DataFailureAlert";
import { useSanitizedMainChartData } from "~/components/child/tradingViewMainChart/utils/hooks/useSanitizedMainChartData";
import { TechnicalTooltip } from "./TechnicalTooltip";

import { useMainChartDetails } from "~/utils/hooks";
import { chartSize } from "../tradingViewMainChart/utils/helpers/tools/createChartStyles";

export const HeatmapDetails = React.memo(
  ({
    details,
    chartDetails,
    permissions,
    selectedAnalyzers,
    id,
    mode = "heatmap",
    className,
  }) => {
    const navigate = useNavigate();

    const {
      chartData,
      theme: mainChartTheme,
      isLoading: mainChartIsLoading,
    } = useMainChartDetails();
    const sanitizedMainChartData = useSanitizedMainChartData(chartData);
    
    const selectedAnalyzersIds = selectedAnalyzers.map((selected) => selected?.uuid);

    const chartProps = useMemo(
      () => ({
        analysisDetailsProp: chartDetails,
        baseDateType:"view",
        selectedAnalysersIds: selectedAnalyzersIds,
        globalMainChartData: sanitizedMainChartData,
        theme: mainChartTheme,
      }),
      [chartDetails, mainChartTheme, selectedAnalyzers]
    );

    const epList = details?.heatmap?.technical?.ep?.filter((item) => {
      return selectedAnalyzersIds?.includes(item?.uuid);
    });
    const tpList = details?.heatmap?.technical?.tp?.filter((item) => {
      return selectedAnalyzersIds?.includes(item?.uuid);
    });
    const slList = details?.heatmap?.technical?.sl?.filter((item) => {
      return selectedAnalyzersIds?.includes(item?.uuid);
    });

    const fundamentalList = details?.heatmap?.fundamental
      ?.map((fundamental) => {
        if (selectedAnalyzersIds?.includes(fundamental.analytic[0].uuid))
          return fundamental;
      })
      .filter(Boolean);
    const onChainList = details?.heatmap?.on_chain
      ?.map((item) => {
        if (selectedAnalyzersIds?.includes(item.uuid)) return item;
      })
      .filter(Boolean);

    const isTechnicalIncluded =
      tpList?.length > 0 || epList?.length > 0 || slList?.length > 0;

    return (
      <div className={`view-heatMap ${className}`}>
        <div className="flex justify-between items-center ">
          <div className="flex items-center gap-2">
            <p className=" font-yekanBakh text-2xl font-bold text-storm-80">
              {details?.heatmap?.title}
            </p>
            {details?.heatmap?.date && (
              <div className=" items-center text-base font-roboto font-normal text-oil-50 flex gap-1">
                {details?.heatmap?.date?.split(" ")[0]}
                <span className=" text-xs font-light">
                  {details?.heatmap?.date?.split(" ")[1]}
                </span>
              </div>
            )}
          </div>
          {permissions &&
            permissions.full_access &&
            mode === "heatmap" &&
            isTechnicalIncluded && (
              <Button
                onClick={() => navigate(`/newSetup?heatmapId=${id}`)}
                className="px-3 !rounded-3xl font-robotoMed"
                variant="primary"
              >
                New Setup
              </Button>
            )}
        </div>

        {details?.heatmap?.description && (
          <p className="view-heatMap__description">
            {details?.heatmap?.description}
          </p>
        )}

        <div className={`${chartSize?.mode2}`}>
          {!mainChartIsLoading && chartDetails && mainChartTheme ? (
            <TVChartContainer {...chartProps} />
          ) : !mainChartIsLoading ? (
            <DataFailureAlert />
          ) : (
            mainChartIsLoading && <Loading />
          )}
        </div>

        {isTechnicalIncluded && (
          <div className="view-heatMap__analysis">
            <p className=" text-lg font-bold text-storm-70">Technical</p>
            <p className=" text-sm font-normal text-oil-50 font-roboto flex items-center gap-1">
              we merged multi data from several analyzers and timeframes and
              technical types.
              <span
                data-tooltip-id="technicalDetails"
                className=" text-sm font-bold items-center justify-center w-4 h-4 border-[1.5px] rounded-full border-storm-70 flex"
              >
                i
              </span>
              <TechnicalTooltip
                id="technicalDetails"
                position="right"
                technicalTypes={details?.heatmap?.technical?.technical_type}
                types={details?.heatmap?.technical?.type}
                timeFrames={details?.heatmap?.technical?.time_frames}
                analyzers={details?.heatmap?.technical?.analysers?.filter(
                  (user) => selectedAnalyzers?.includes(user)
                )}
              />
            </p>
            <div className="technical-view__entries">
              <PairsSymbol
                className="text-sm font-robotoMed"
                image={details?.heatmap?.pair?.image}
                pair={details?.heatmap?.pair?.pair}
              />
              {epList?.length > 0 && (
                <Accordion
                  className={{ root: "technical-view__entries__accordion__ep" }}
                  label={{
                    leftPart: (
                      <span className="technical-view__entries__accordion__ep__label">
                        EP
                        <span className="technical-view__entries__accordion__ep__label-count">
                          ({epList?.length})
                        </span>
                      </span>
                    ),
                  }}
                  openDefault={true}
                >
                  {epList?.map((epItem, index) => {
                    return (
                      <TechnicalPointPreview
                        label={`EP ${epList?.length > 1 ? index + 1 : ""}`}
                        key={index}
                        value={`${epItem?.base_amount}${
                          epItem?.max_amount !== 0
                            ? ` - ${epItem?.max_amount}`
                            : ""
                        }`}
                        note={epItem?.point_description}
                        info={{
                          positionType: epItem?.position_type,
                          technicalTypes: epItem?.technical_type,
                          timeFrame: epItem?.time_frame,
                          analyser: epItem?.user_name,
                        }}
                      />
                    );
                  })}
                </Accordion>
              )}
              {tpList?.length > 0 && (
                <Accordion
                  className={{ root: "technical-view__entries__accordion__tp" }}
                  label={{
                    leftPart: (
                      <span className="technical-view__entries__accordion__tp__label">
                        TP
                        <span className="technical-view__entries__accordion__tp__label-count">
                          ({tpList?.length})
                        </span>
                      </span>
                    ),
                  }}
                  openDefault={true}
                >
                  {tpList?.map((tpItem, index) => (
                    <TechnicalPointPreview
                      label={`TP ${tpList?.length > 1 ? index + 1 : ""}`}
                      riskToReward={tpItem?.rr}
                      key={index}
                      value={`${tpItem?.base_amount}${
                        tpItem?.max_amount !== 0
                          ? ` - ${tpItem?.max_amount}`
                          : ""
                      }`}
                      note={tpItem?.point_description}
                      info={{
                        positionType: tpItem?.position_type,
                        technicalTypes: tpItem?.technical_type,
                        timeFrame: tpItem?.time_frame,
                        analyser: tpItem?.user_name,
                      }}
                    />
                  ))}
                </Accordion>
              )}

              {slList?.length > 0 &&
                slList?.map((slItem, index) => (
                  <TechnicalPointPreview
                    key={index}
                    label={`SL ${slList?.length > 1 ? index + 1 : ""}`}
                    positionType={slItem?.position_type}
                    className="font-robotoMed"
                    value={`${slItem?.base_amount}${
                      slItem?.max_amount !== 0 ? ` - ${slItem?.max_amount}` : ""
                    }`}
                    note={slItem?.point_description}
                    info={{
                      positionType: slItem?.position_type,
                      technicalTypes: slItem?.technical_type,
                      timeFrame: slItem?.time_frame,
                      analyser: slItem?.user_name,
                    }}
                  />
                ))}
            </div>
          </div>
        )}
        {mode === "heatmap" && fundamentalList?.length > 0 && (
          <div className="view-heatMap__analysis">
            <p className=" text-lg font-bold text-storm-70">Fundamental</p>
            {fundamentalList?.map((fundamental, index) => {
              return (
                <div className="flex flex-col gap-6" key={index}>
                  <div className="border-y border-storm-30 p-2 flex items-center gap-2">
                    <PairsSymbol
                      className="text-sm font-robotoMed"
                      image={fundamental?.analytic[0]?.pair?.image}
                      pair={fundamental?.analytic[0]?.pair?.pair}
                    />
                    <span className="text-sky-60">Fundamental</span>
                    <span className="text-sky-60 font-roboto">{fundamental?.analytic[0]?.user_name}</span>
                  </div>
                  {fundamental?.description && (
                    <p className=" text-right font-yekanBakh text-base font-normal text-oil-60 ">
                      {fundamental?.description}
                    </p>
                  )}
                  {fundamental?.analytic[0]?.pictures?.length > 0 && (
                    <ImageSlider images={fundamental?.analytic[0]?.pictures} />
                  )}
                  <div className="flex flex-col">
                    {fundamental?.analytic?.map((analytic, index) => {
                      return (
                        <FundamentalEntry
                          key={index}
                          id={index}
                          date={analytic.analysis_date.split(" ")[0]}
                          description={analytic.analysis_description}
                          label={analytic.title}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {mode === "heatmap" && onChainList?.length > 0 && (
          <div className="view-heatMap__analysis">
            <p className=" text-lg font-bold text-storm-70">On Chain</p>
            {onChainList?.map((onChain, index) => {
              return (
                <div
                  key={index}
                  className="p-2 pb-4 rounded-lg bg-storm-light flex flex-col gap-4"
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-1 text-sm">
                      <PairsSymbol
                        className="text-sm font-robotoMed"
                        image={onChain.pair.image}
                        pair={onChain.pair.pair}
                      />

                      <span className="text-oil-60 font-extrabold text-base font-yekanBakh">
                        {onChain.title}
                      </span>
                      <span className="font-roboto font-light text-oil-60 ">
                        {onChain?.date?.split(" ")[0]}
                      </span>
                    </div>

                    <span className=" text-xs font-light text-storm-50 py-[0.375rem] px-2 bg-storm-20 rounded-2xl font-roboto">
                      {onChain?.user}
                    </span>
                  </div>
                  {onChain?.description && (
                    <p className=" font-yekanBakh text-base font-normal text-oil-60 text-right">
                      {onChain?.description}
                    </p>
                  )}
                  {onChain?.pictures && (
                    <ImageSlider images={onChain?.pictures} />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
);
