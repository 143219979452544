import React from "react";
import AnalysisHeader from "~/components/child/AnalysisHeader";
import UploadImage from "~/components/child/ui/imageUpload/Upload";
import ImagesPreview from "~/components/child/ui/imageUpload/Preview";
import WriteSomething from "./WriteSomething";

const OnChain = ({
  onSubmit,
  onBack,
  analysisValues,
  setAnalysisValues,
  isLoading,
  errorMessage,
}) => {
  return (
    <>
      <div className="main__router__container">
        <div className="on-chain scrollbar-style-thumb scrollbar-style">
          <div className="on-chain__container">
            <div className="on-chain__box">
              <div className="on-chain__box__left">
                <div className="on-chain__box__left__title">New analysis</div>

                <AnalysisHeader
                  pairs={analysisValues.new.pair}
                  type="Onchain"
                />

                <div className="items-center bg-base border border-solid border-storm-20 rounded-xl flex font-roboto justify-between p-3">
                  <div className=" text-oil-60 font-robotoMed">Title</div>
                  <input
                    value={analysisValues.onChain.title || ""}
                    onChange={(e) =>
                      setAnalysisValues({
                        ...analysisValues,
                        onChain: {
                          ...analysisValues.onChain,
                          title: e.target.value,
                        },
                      })
                    }
                    className="text-oil-60 border-none outline-none w-full font-yekanBakh text-base text-right ml-2"
                    type="text"
                  />
                </div>
                <div className="on-chain__box__left__description">
                  <WriteSomething
                    placeholder="* write something"
                    setValue={(value) =>
                      setAnalysisValues({
                        ...analysisValues,
                        onChain: {
                          ...analysisValues.onChain,
                          description: value,
                        },
                      })
                    }
                    value={analysisValues.onChain.description || ""}
                  />
                </div>
                <div className="on-chain__box__left__submitButtons">
                  <button
                    onClick={() => onBack()}
                    className="btn-cstm btn-outline-dark-cstm"
                  >
                    Back
                  </button>
                  <button
                    onClick={() => onSubmit()}
                    className="btn-cstm btn-dark-cstm"
                    disabled={
                      isLoading ||
                      analysisValues.onChain.images.length === 0 ||
                      analysisValues.onChain.title.length === 0 ||
                      analysisValues.onChain.description.length === 0
                    }
                  >
                    Apply
                  </button>
                </div>
                {errorMessage && Object.keys(errorMessage) && (
                  <div className="on-chain__box__left__errorBox">
                    {Object.keys(errorMessage)?.map((key) => (
                      <p
                        key={key}
                        className="on-chain__box__left__errorBox__errorItem"
                      >
                        {errorMessage[key]}
                      </p>
                    ))}
                  </div>
                )}
              </div>

              <div className="on-chain__box__right">
                <ImagesPreview
                  images={analysisValues.onChain.images}
                  onRemove={(id) =>
                    setAnalysisValues({
                      ...analysisValues,
                      onChain: {
                        ...analysisValues.onChain,
                        images: analysisValues.onChain.images.filter(
                          (item) => item.id !== id
                        ),
                      },
                    })
                  }
                  onSetAsPrimary={(id) =>
                    setAnalysisValues({
                      ...analysisValues,
                      onChain: {
                        ...analysisValues.onChain,
                        images: analysisValues.onChain.images.map((item) => ({
                          ...item,
                          active: id === item.id ? 1 : 0,
                        })),
                      },
                    })
                  }
                />
                {analysisValues.onChain.images.length < 5 && (
                  <UploadImage
                    information="Add photo"
                    onAddNewImage={(e) => {
                      if (e.target.files[0])
                        setAnalysisValues({
                          ...analysisValues,
                          onChain: {
                            ...analysisValues.onChain,
                            images: [
                              ...analysisValues.onChain.images,
                              {
                                id:
                                  analysisValues.onChain.images.length > 0
                                    ? analysisValues.onChain.images[
                                        analysisValues.onChain.images.length - 1
                                      ].id + 1
                                    : 0,
                                name: e.target.files[0].name,
                                src: URL.createObjectURL(e.target.files[0]),
                                file: e.target.files[0],
                                active:
                                  analysisValues.onChain.images.length === 0
                                    ? 1
                                    : 0,
                              },
                            ],
                          },
                        });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnChain;
