import React from "react";
import AddPhotoSvg from "../../../../assets/icon/svgJsx/AddPhotoSvg";

const Upload = ({ onAddNewImage, information }) => {
  return (
    <div className="upload">
      <form encType="multipart/form-data" className="upload__form">
        <input
          type="file"
          accept="image/*"
          onChange={(e) => onAddNewImage(e)}
        />

        <button className="upload__form__info">
          <AddPhotoSvg />
          {information}
        </button>
      </form>
    </div>
  );
};

export default Upload;
