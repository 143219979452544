import React, { useEffect } from "react";

const Header = ({
  pair,
  setPair,
  handleClear,
  handlePlotZones,
  isPlotZones,
  setIsPlotZones,
  imbalanceValue,
  setImbalanceValue,
  theme
}) => {

  useEffect(() => {
    const intervalId = setInterval(() => {

      if (imbalanceValue >=80 && imbalanceValue <= 99) {
        setImbalanceValue(imbalanceValue)
      }else if(imbalanceValue <= 79) {
        setImbalanceValue(80)
      } else if(imbalanceValue >= 99) {
        setImbalanceValue(99)
      }
    }, 300);

    return () => {
      clearInterval(intervalId);
    };
  }, [imbalanceValue]);

  const handleInputChange = (e) => {
    const value = Number(e.target.value);
    if (!isNaN(value)) {
      setImbalanceValue(value);
    }
  };


  const handleChangePair = (pairVal) => {
    setPair(pairVal);
  };

  useEffect(() => {
    setIsPlotZones(false);
  }, [pair]);



  const colors = {
    bgColorLight : 'bg-sky-key',
    bgColorDark : 'bg-sky-70',
  }

  return (
      <div className={
      `flex justify-between border-y-4 p-1 
      ${ theme === 'dark' ? 'bg-chartDark-key border-chartDark-10' : 'bg-storm-light border-storm-20' }`}
      >
      
      <div>
        <button
          onClick={() => handleChangePair("BTCUSDT")} 
          className={`p-2 text-sky-light ${ theme === 'dark' ? colors?.bgColorDark : colors?.bgColorLight}`}
        >
          BTC/USDT
        </button>
        <button
          onClick={() => handleChangePair("ETHUSDT")}
          className={`p-2 ml-1 text-sky-light ${ theme === 'dark' ? colors?.bgColorDark : colors?.bgColorLight}`}
        >
          ETH/USDT
        </button> 
        <button
          onClick={() => handleChangePair("ETHBTC")}
          className={`p-2 ml-1 text-sky-light ${ theme === 'dark' ? colors?.bgColorDark : colors?.bgColorLight}`}
        >
          ETH/BTC
        </button>
      </div>

      <div className="flex">
      <input
          value={imbalanceValue}
          onChange={handleInputChange}
          type="text"
          className={"outline-none text-center text-sky-70 rounded	mr-2 w-10 border-solid border-2 border-sky-key"}
        />
  

        <button
          onClick={() => handlePlotZones()}
          className={`${isPlotZones ? "bg-leaf-40" : "bg-leaf-key"}  p-2 text-sky-light`}
        >
          PLOT ZONES
        </button>
        <button
          onClick={() => handleClear()}
          className={"bg-infrared-key text-sky-light p-2 ml-1"}
        >
          CLEAR
        </button>
      </div>
    </div>
  );
};

export default Header;
