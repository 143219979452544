import { useEffect } from "react";

import { mainChartApiUpdateHelper } from "~/components/child/tradingViewMainChart/utils/helpers/api/mainChartApiUpdateHelper";
import DataFailureAlert from "~/components/child/tradingViewMainChart/ui/DataFailureAlert";
import { chartSize } from "~/components/child/tradingViewMainChart/utils/helpers/tools/createChartStyles";
import { TVChartContainer } from "~/components/child/tradingViewMainChart/tvChartContainer/fullChart/TVChartContainer";
import { Loading } from "~/components/child/ui/Loading";

import { useMainChartDetails } from "~/utils/hooks";

const FullChart = () => {
  const {
    isLoading,
    chartData,
    setChartData,
    theme,
    setTheme,
    favorites,
    setFavorites,
    layoutList,
    setLayoutList,
    templateList,
    setTemplateList,
  } = useMainChartDetails();

  useEffect(() => {
    // * main chart update in => [near real time]
    let intervalId;
    const updateChart = () => {
      const parameters = localStorage.getItem("full_chart_near_real_time");
      if (parameters) {
        mainChartApiUpdateHelper.nearRealTime(
          intervalId,
          "main_chart_data",
          parameters
        );
      }
    };
    intervalId = setInterval(updateChart, 60000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={`${chartSize?.fullScreen}`}>
      {!isLoading &&
      chartData &&
      theme &&
      favorites &&
      layoutList &&
      templateList ? (
        <TVChartContainer
          chartData={chartData}
          setChartData={setChartData}
          theme={theme}
          setTheme={setTheme}
          favorites={favorites}
          setFavorites={setFavorites}
          layoutList={layoutList}
          setLayoutList={setLayoutList}
          templateList={templateList}
          setTemplateList={setTemplateList}
        />
      ) : !isLoading ? (
        <DataFailureAlert />
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default FullChart;
