import { widgetStyleDetermine } from "../determinators/widgetStyleDetermine";
import { tvColors } from "../helpers/tools/tvColors";

export class WidgetCreator {
  constructor(tvWidget) {
    this.tvWidget = tvWidget;
  }

  trendLine(price, timeRange, widgetType, additionalOverrides) {
    let chart = this.tvWidget.chart();
    let { color: linecolor, style } = widgetStyleDetermine(widgetType);
    return chart.createMultipointShape(
      [
        { price: Number(price), time: timeRange?.timeStart },
        { price: Number(price), time: timeRange?.timeEnd },
      ],
      {
        shape: "trend_line",
        lock: true,
        disableUndo: true,
        disableSelection: true,
        disableSave: true,

        overrides: {
          linecolor: linecolor,
          linestyle: style,
          ...additionalOverrides,
        },
      }
    );
  }

  rectangle(price, timeRange, widgetType, additionalOverrides) {
    let chart = this.tvWidget.chart();
    let { color: backgroundColor } = widgetStyleDetermine(widgetType);
    return chart.createMultipointShape(
      [
        { price: Number(price[0]), time: timeRange?.timeStart },
        { price: Number(price[1]), time: timeRange?.timeEnd },
      ],
      {
        shape: "rectangle",
        lock: true,
        disableUndo: true,
        disableSelection: true,
        disableSave: true,
        overrides: {
          color: tvColors.opacity0,
          backgroundColor: backgroundColor,
          ...additionalOverrides,
        },
      }
    );
  }

  verticalLine(date, widgetType, additionalOverrides) {
    let chart = this.tvWidget.chart();
    let { color: linecolor, style } = widgetStyleDetermine(widgetType);
    return chart.createShape(
      { time: date },
      {
        shape: "vertical_line",
        lock: true,
        disableUndo: true,
        disableSelection: true,
        disableSave: true,
        overrides: {
          linecolor: linecolor,
          linestyle: style,
          ...additionalOverrides,
        },
      }
    );
  }
}
