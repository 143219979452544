import React, { useEffect, useRef, useState } from "react";

import { widget } from "~/charting_library";
import { createChartConfig } from "~/components/child/tradingViewMainChart/config/createChartConfig.js";
import { createChartTimeFrame } from "../../../utils/creators/createChartTimeFrame";
import { createPairDataConfig } from "../../../utils/creators/createPairDataConfig";
import { convertIntervalToTradingViewFormat } from "../../../utils/converters/convertTimeFrameToTradingViewFormat";
import { convertDateToUnixTime } from "../../../utils/converters/convertDateToUnixTime";
import { convertChartTimeZone } from "../../../utils/converters/convertChartTimeZone";
import { setIntervalChartSource } from "../../../utils/chartSourceModifiers/setIntervalChartSource";
import { WidgetCreator } from "../../../utils/classes/widgetCreator";
import { setThemeChartSource } from "../../../utils/chartSourceModifiers/setThemeChartSource";
import { plotVerticalLineAtCreationTime } from "../../../utils/helpers/plotting/plotVerticalLineAtCreationTime";

export const TVChartContainer = ({
  pairDetail,
  initInterval,
  createdAt,
  initChartData,
  details,
  theme,
}) => {
  const chartContainerRef = useRef();
  const { baseChartConfig, specificConfigs } = createChartConfig("fundamental");
  const [interval, setInterval] = useState(
    convertIntervalToTradingViewFormat(initInterval)
  );

  const parsedChartData = JSON.parse(JSON.parse(initChartData));
  const modifyChartDataInterval =
    setIntervalChartSource(parsedChartData, interval) ?? null;
  const modifyChartDataTheme = setThemeChartSource(
    modifyChartDataInterval,
    theme
  );
  const finalChartData = convertChartTimeZone(modifyChartDataTheme, 1);

  const { timeFrom: timeFrameLoadChartFrom, timeTo: timeFrameLoadChartTo } =
    createChartTimeFrame(interval, createdAt.split(" ")[0]);
  const {
    base_symbol: baseSymbol,
    quote_symbol: quoteSymbol,
    exchange,
  } = pairDetail;
  const { pair, dataFeed: datafeedUrl } = createPairDataConfig(
    baseSymbol,
    quoteSymbol,
    exchange
  );

  useEffect(() => {
    const widgetOptions = {
      ...baseChartConfig?.defaultProps,
      container: chartContainerRef.current,
      datafeed: baseChartConfig?.createDatafeed(datafeedUrl),
      disabled_features: specificConfigs?.detail?.disabledFeatures,
      enabled_features: specificConfigs?.detail?.enabledFeatures,
      theme: theme,
      symbol: pair,
      interval: interval,
      saved_data: finalChartData,
      timeframe: { from: timeFrameLoadChartFrom, to: timeFrameLoadChartTo },
    };

    const tvWidget = new widget(widgetOptions);
    const createWidget = new WidgetCreator(tvWidget);

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        if (details && details.length > 0) {
          for (const detail of details) {
            const initDate = detail?.analysis_date;
            const date = initDate;
            const dateToUnixTime = convertDateToUnixTime(1, date.split(" ")[0]);
            createWidget.verticalLine(dateToUnixTime, "fundamentalDatePoint");
          }
        }

        // show created at by vertical_line => style :dashed
        plotVerticalLineAtCreationTime(createdAt, createWidget);

        tvWidget
          .activeChart()
          .onIntervalChanged()
          .subscribe(null, (interval) => {
            setInterval(convertIntervalToTradingViewFormat(interval));
          });
      });
    });

    return () => {
      tvWidget.remove();
    };
  }, [finalChartData, interval]);

  return <div ref={chartContainerRef} className={"TVChartContainer"} />;
};
